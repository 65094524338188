import {Link} from "react-router-dom";

export default function EducationFooter({data}) {

    const {nagar_data, district_data, state_data} = data.address;

    const social_media = data.social_media?.filter(current => current.url)

    return (
        <footer className="bg-education">
            <div className="max-w-1170 mx-auto py-12 text-white">
                <div className="grid grid-cols-3 gap-4">
                    <div className="mr-12">
                        <h3 className="text-xl font-medium mb-4 uppercase">Product</h3>
                        <ul className="grid grid-cols-2">
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Events</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">About Us</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Job</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Contact Us</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Gallery</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Review</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Teams</Link></li>
                        </ul>
                    </div>
                    <div className="justify-center">
                        <h3 className="text-xl font-medium mb-4 uppercase">Contact Us</h3>
                        <ul className="max-w-sm space-y-1.5">
                            <li className="flex items-baseline">
                                <img className="inline-block w-3" src="/assets/images/edu_location.svg" alt=""/>
                                <span
                                    className="ml-4">{nagar_data?.name}, {district_data?.name}, {state_data?.name}</span>
                            </li>
                            <li className="flex items-baseline">
                                <img className="inline-block w-3" src="/assets/images/edu_phone.svg" alt=""/>
                                {data.phone.length
                                    ? data.phone.map((current, index) => {
                                        return <span className="ml-4" key={current.id}>
                                        <a href={`tel:${current.value}`}>
                                            {current.value} {(data.phone.length > 1 && index === 0) && ", "}
                                        </a>
                                    </span>
                                    })
                                    : <span>n/a</span>}
                            </li>
                            <li className="flex items-baseline">
                                <img className="inline-block w-3" src="/assets/images/edu_mail.svg" alt=""/>
                                {data.email.length
                                    ? data.email.map((current, index) => {
                                        return <span className="ml-4" key={current.id}>
                                            <a href={`mailto:${current.value}`}>
                                                {current.value} {(data.email.length > 1 && index === 0) && ", "}
                                            </a>
                                    </span>
                                    })
                                    : <span>n/a</span>
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="justify-self-end">
                        <div className="flex items-center ">
                            <span className="mr-8 text-xl">Follow us:</span>
                            {social_media.map((current, index) => {
                                return (
                                    <a href={current.url} target="_blank" rel="noreferrer" key={index + Math.random()}>
                                        <img src={`/assets/images/${current.type}.svg`} alt="" width={20}
                                             className="inline-block mr-2"/>
                                    </a>
                                )
                            })}
                        </div>
                        <div className="mt-6">
                            <input type="email" className="rounded-l-md bg-white text-black px-4 py-2"
                                   placeholder="Your email"/>
                            <button className="rounded-r-md px-5 py-2 bg-gray-700 text-white">SUBSCRIBE</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-5 border-t border-blue-300">
                <div className="max-w-1170 mx-auto py-6 text-white text-sm flex justify-between">
                    <span>© Copyrights {new Date().getFullYear()}.
                        <strong className="capitalize"><Link to={`/${data.slug}`}> {data.name}</Link>
                    </strong>. All rights reserved.</span>
                    <span>Powered by: <strong><a href="https://findallnepal.com">Find All Nepal</a></strong></span>
                </div>
            </div>
        </footer>
    )
}