import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {postTypes} from "../pages/voice/VoiceLayout";
import {useSelector} from "react-redux";

export default function PleaseLoginPopup({showLoginPopup, handleShowLoginPopup}) {

    return (
        <>
            <Transition appear show={showLoginPopup} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => handleShowLoginPopup(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-60"/>
                    </Transition.Child>

                    <div className="fixed top-16 inset-x-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95"
                                              enterTo="opacity-100 scale-100" leave="ease-in duration-200"
                                              leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                                <Dialog.Panel
                                    className="w-full max-w-md transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h2"
                                                  className="flex items-center px-4 py-3 border-b border-b-gray-200 font-bold text-gray-800">
                                        <img src="/assets/info.svg" alt="" className="inline-block w-5 h-5 mr-2"/>
                                        <span>Please login !</span>
                                    </Dialog.Title>
                                    <div
                                        className="flex flex-col space-y-8 items-center w-full justify-center px-4 py-8">
                                        <span>You need to be logged in to perform this action.</span>
                                        <div className="flex justify-center items-center gap-4">
                                            <a className="bg-bColor text-white py-2 px-8 rounded-full block hover:opacity-90 border-2 border-bColor w-28 text-center"
                                               href={`//account.${process.env.REACT_APP_BASE_DOMAIN}/signin?goto=${window.location.href}`}
                                            > Login
                                            </a>
                                            <span className="text-gray-500">- OR -</span>
                                            <a className="border-2 border-bColor text-bColor py-2 px-8 rounded-full block font-bold hover:opacity-90 w-28 text-center"
                                               href={`//account.${process.env.REACT_APP_BASE_DOMAIN}/signin?goto=${window.location.href}`}
                                            > Signup
                                            </a>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

        </>
    )
}

