import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {doCreatePosts, doSetImageError, doUploadImage} from "../store/voiceSlice";
import {postTypes as postShortName} from "../pages/voice/VoiceHomeNew";
import {postTypes} from "../pages/voice/VoiceLayout";
import {useParams} from "react-router-dom";
import PostSuccessNotification from "./voice/PostSuccessNotification";

export default function CreatePostPopup({
                                            showCreatePost,
                                            handleShowCreatePost,
                                            setSelectedType,
                                            selectedType,
                                            setShowCreatePost
                                        }) {

    const {data} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const {create, image} = useSelector(state => state.voice);
    // const [selectedImages, setSelectedImages] = useState([]);
    // const [imageError, setImageError] = useState({status: false, message: ""});
    const param = useParams();

    const detailsRef = useRef(null);
    const imageRef = useRef(null);

    const handleImageChange = (event) => {
        dispatch(doSetImageError({status: false, message: ""}))
        const img = event.target.files[0]
        if (img.type.slice(0, 5) === "image") {
            const validImage = new Image();
            validImage.src = URL.createObjectURL(img);
            validImage.onload = function () {
                //2MiB in bytes (mebibyte)
                if (img.size > 1048576) { //1MiB for now
                    // if (img.size > 2097152) {
                    dispatch(doSetImageError({status: true, message: "Image bigger than 2MB."}))
                } else {
                    const formData = new FormData();
                    formData.append("file", img)
                    imageRef.current.value = null;
                    dispatch(doUploadImage({formData}));
                }
            }
            validImage.onerror = function () {
                dispatch(doSetImageError({status: true, message: "Image format not valid/supported."}))
            }
        } else {
            imageRef.current.value = null;
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const imageSlugList = image.list.map(current => current.slug)
        const formData = new FormData();
        formData.append("_io", detailsRef.current.value);
        formData.append("_olo", "2");
        for (let i = 0; i < imageSlugList.length; i++) {
            formData.append('_ima[]', imageSlugList[i]);
        }
        // formData.append("_ima", [...imageSlugList]);
        dispatch(doCreatePosts({type: postShortName[selectedType], formData, slug: param.type || "all", selectedType}))
            .unwrap()
            .then(() => {
                handleShowCreatePost()
            }).catch(() => {
        })
    }

    return (
        <>

            <Transition appear show={showCreatePost} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setShowCreatePost(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-60"/>
                    </Transition.Child>

                    <div className="fixed top-0 inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-start justify-center p-4 text-center">
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95"
                                              enterTo="opacity-100 scale-100" leave="ease-in duration-200"
                                              leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                                <Dialog.Panel
                                    className="w-500 transform rounded-md text-left align-middle shadow-xl transition-all overflow-hidden">
                                    {!selectedType &&
                                        <div className="bg-white pb-4 pt-5 px-8 mt-20 relative rounded-md">
                                            <img src="/assets/voice-add.png" alt="" width={80} height={80}
                                                 className="pointer-events-none border-8 border-white rounded-full absolute -mt-14 transition -translate-x-1/2 left-1/2"/>
                                            <button onClick={handleShowCreatePost}>
                                                <img src="/assets/arrow-right.svg" alt=""
                                                     className="pointer-events-none transform rotate-180 w-8"/>
                                            </button>
                                            <h2 className="uppercase text-center text-xl text-bColor font-bold">Create
                                                post</h2>
                                            <ul className="text-bColor space-y-2 mt-4">
                                                {postTypes.map(item => {
                                                    return (
                                                        <li key={item}>
                                                            <button
                                                                className="bg-gray-100 p-3 flex space-x-4 w-full hover:bg-gray-200 transition-colors rounded-md"
                                                                onClick={() => setSelectedType(item)}>
                                                                <img src={`/assets/voice-${item}.svg`} alt=""
                                                                     className="w-5 h-5 filter"/>
                                                                <span className="text-black capitalize">{item}</span>
                                                            </button>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    }
                                    {(selectedType && data) &&
                                        <div className="bg-white py-4 px-8 mt-20 relative rounded-md">
                                            <div className="flex space-x-4">
                                                <button onClick={() => setSelectedType("")}>
                                                    <img src="/assets/arrow-right.svg" alt=""
                                                         className="pointer-events-none transform rotate-180 w-8"/>
                                                </button>
                                                <h2 className="text-xl font-medium">Create Post</h2>
                                            </div>
                                            <div className="mt-4 space-x-3 flex items-center">
                                                <DisplayPicture/>
                                                <div className="flex flex-col">
                                                    <h3 className="font-medium">{data.user.displayName}</h3>
                                                    <span
                                                        className="capitalize text-xs font-light">{selectedType}</span>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit}>
                                        <textarea placeholder="Write something" ref={detailsRef} rows={6}
                                                  className="w-full my-4 text-lg border border-transparent focus:border-gray-200 ring-0 outline-none p-2 max-h-60"/>
                                                <div
                                                    className="grid gap-1 grid-cols-[repeat(auto-fill,_minmax(49px,_50px))]">
                                                    {image.list.map((current, index) => {
                                                        return (
                                                            <div title={current.name} key={index}>
                                                                <img width={50} height={50} src={current.uri} alt=""
                                                                     className="h-[50px] w-[50px] rounded-md border-2 border-slate-200 pointer-events-none"
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                    {image.isUploading &&
                                                        <div
                                                            className="h-[50px] w-[50px] rounded-md border-2 border-slate-100 flex justify-center items-center">
                                                            <img src="/assets/round-loader.svg" alt="" className="w-5"/>
                                                        </div>
                                                    }
                                                </div>
                                                <label
                                                    className={`group py-2 px-4 flex justify-between items-center text-gray-400 transition-all border border-slate-200 mt-2 rounded-md ${image.isUploading ? "cursor-not-allowed" : "cursor-pointer"}`}>
                                                    <span className="font-bold group-hover:text-gray-500">Add images to your post (1MB)</span>
                                                    {/*<img src="/assets/add-btn.png" alt="" width={40} height={40}*/}
                                                    {/*     className="group-hover:brightness-95"/>*/}
                                                    <img src="/assets/image.png" alt=""
                                                         className="w-8 h-8 pointer-events-none p-1 group-hover:brightness-75"/>
                                                    <input type="file" className="hidden" ref={imageRef}
                                                           accept=".png, .jpg, .jpeg, .gif" disabled={image.isUploading}
                                                           onChange={handleImageChange}/>
                                                </label>
                                                <div className="flex space-x-4 mt-4">
                                                    <button
                                                        className="px-6 py-2.5 font-medium bg-gray-100 hover:bg-gray-200 rounded-md"
                                                        onClick={handleShowCreatePost} type="button">Cancel
                                                    </button>
                                                    {create.isLoading
                                                        ? <button type="submit" disabled
                                                                  className="px-6 py-2.5 font-medium bg-bColor-light text-bColor flex-grow flex justify-center items-center rounded-md">
                                                            <img src="/assets/round-loader.svg" alt="" className="w-5"/>
                                                        </button>
                                                        : <button type="submit"
                                                                  className="px-6 py-2.5 font-medium bg-bColor-light text-bColor-dark flex-grow rounded-md">
                                                            Post
                                                        </button>
                                                    }
                                                </div>
                                            </form>
                                            <div className="text-sm text-red-700 my-4 text-center font-bold">
                                                {create.isError.status && <div className="bg-red-200 p-2">
                                                    <span className="block">{create.isError.message}</span>
                                                </div>}
                                            </div>
                                            <div className="text-sm text-red-700 my-4 text-center font-bold"
                                                 id="image-error">
                                                {image.isError.status && <div className="bg-red-200 p-2">
                                                    <span className="block">{image.isError.message}</span>
                                                </div>}
                                            </div>
                                        </div>
                                    }
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>


            <PostSuccessNotification/>
        </>
    );
}

function DisplayPicture() {

    const {data, isLoading} = useSelector(state => state.user);
    const regex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');


    if (!isLoading && data?.user) {
        let initials = [...data?.user?.displayName.matchAll(regex)] || [];
        initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
        return (
            <div className="w-9 h-9 rounded-full flex justify-center items-center">
                {data?.user?.image
                    ? <img src={data?.user?.image?.path} alt="" className="w-9 h-9 rounded-full pointer-events-none"
                           width={36} height={36}/>
                    : <span
                        className="w-9 h-9 rounded-full border-2 border-gray-200 bg-red-500 text-white block text-sm flex items-center justify-center">{initials}</span>
                }
            </div>
        )
    }

    return (
        <div className="w-9 h-9 rounded-full flex justify-center items-center bg-gray-200"/>
    )

}