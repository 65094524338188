import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {externalLinks} from "../constants/externalLinks";

export default function DirectionDialog({handleDirectionDialog, isOpen, setIsOpen}) {
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-60"/>
                    </Transition.Child>

                    <div className="fixed inset-0">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95"
                                              enterTo="opacity-100 scale-100" leave="ease-in duration-200"
                                              leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                                <Dialog.Panel
                                    className="w-full max-w-md transform rounded-md bg-white shadow-xl transition-all p-6 my-8">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Direction not available
                                    </Dialog.Title>
                                    <div className="mt-4 text-sm text-gray-500">
                                        <p>
                                            <strong>Get Direction</strong> feature is currently not available on web
                                            version, please use the mobile app instead to get the direction.
                                        </p>
                                        <div>
                                            <span className="mt-4 mb-2 block">Get the App</span>
                                            <div className="flex space-x-2">
                                                <a href={externalLinks.appGoolge} target="_blank" rel="noreferrer">
                                                    <img src="/assets/images/playstore.svg" alt=""/>
                                                </a>
                                                <a href={externalLinks.appApple} target="_blank" rel="noreferrer">
                                                    <img src="/assets/images/appstore.svg" alt=""/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                            onClick={handleDirectionDialog}
                                        >
                                            Got it, thanks!
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

        </>
    )
}