import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import {useEffect, useState} from "react";
import EducationFooter from "../../components/education/EducationFooter";
import {SRLWrapper} from "simple-react-lightbox";
import {useParams} from "react-router-dom";
import {useGetOrgGalleryImagesQuery} from "../../services/directoryApi";
import {Helmet} from "react-helmet-async";

export default function Gallery({data}) {

    const param = useParams();
    const {isFetching, data: galleryData} = useGetOrgGalleryImagesQuery(param.slug);


    function getRandomSize(min, max) {
        return Math.round(Math.random() * (max - min) + min);
    }

    const [sizes, setSizes] = useState([]);

    useEffect(() => {
        const temp = []
        for (let i = 0; i < 10; i++) {
            let width = getRandomSize(200, 400);
            let height = getRandomSize(200, 400);
            temp.push({width, height})
        }
        setSizes(temp)
    }, [])

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`Gallery | ${data.name}`}/>
                <meta property="og:url" content={`https://findallnepal.com/${data.slug}/gallery`}/>
                <link rel="canonical" href={`https://findallnepal.com/${data.slug}/gallery`}/>
                <title>{`Gallery | ${data.name}`}</title>
            </Helmet>
            <section id="hero">
                <EducationTopMenu data={data}/>
                <EducationMainMenuWithBackground
                    background={data.featuredImage.path || "/assets/images/edu_events_bg.png"} height="25rem"
                    data={data}/>
            </section>
            <main className="my-10">
                <div className="max-w-1170 mx-auto">
                    <section id="gallery">
                        <div className="flex space-x-4 mb-8">
                            <div
                                className="tab-bubble flex justify-center items-center flex-1 tab-bubble-left active-tab-bubble">
                                <span className="block p-6 text-3xl font-bold uppercase">Photo Gallery</span>
                            </div>
                            {/*<div className="tab-bubble flex justify-center items-center flex-1 tab-bubble-right">*/}
                            {/*    <span className="block p-6 text-3xl font-bold uppercase">Video Gallery</span>*/}
                            {/*</div>*/}
                        </div>
                        {isFetching && <div className="mx-auto my-10">loading...</div>}
                        {(!isFetching && galleryData.organizationWithGalleryImages) &&
                            <div className="gallery-grid">
                                <SRLWrapper>
                                    {galleryData.organizationWithGalleryImages.gallery.map((current) => {
                                        return (
                                            <a href={`${current.path}`} key={current.slug}>
                                                <img src={`${current.thumb}`} alt="" loading="lazy"/>
                                            </a>
                                        )
                                    })}
                                </SRLWrapper>
                            </div>
                        }
                    </section>
                </div>
            </main>
            <EducationFooter data={data}/>
        </>
    )
}