const routes = {
    home: "/",
    searchByQuery: "/search",
    siteUrl: process.env.REACT_APP_BASE_DOMAIN,
    unClaimed: "/unclaimed",
    underConstruction: "/under-construction",
    people: "/people",
    organization: "/organization",
    educationAll: "/education/all",
    searchByCat: "/search",
    // education: "/search/education",
    advertising: "/advertising",
    about: "/about",
    career: "/career",
    gallery: "/gallery",
    jobs: "/jobs",
    newsNew: "/news-new",
    news: "/news",
    orderConfirmed: "/order-confirmed",
    payment: "/payment",
    checkoutPreview: "/checkout-preview",
    checkoutInformation: "/checkout-information",
    wishList: "/my-wishlist",
    cart: "/my-cart",
    product: "/product",
    productCategory: "/category",
    shop: "/shop",
    literature: "literature",
    jokes: "jokes",
    opinion: "opinion",
    politics: "politics",
    status: "status",
    idea: "idea",
    voice: "/voice",
    privacy: "/privacy-policy",
    terms: "/terms-of-services",
}

export default routes