import {useParams} from "react-router-dom";
import {useGetNewsBySlugQuery} from "../services/newsApi";
import {Helmet} from "react-helmet-async";
import {DateTime} from "luxon";
import React, {Fragment, useState} from "react";
import ContentLoader from "react-content-loader";
import millify from "millify";
import {doToggleLoginPopup} from "../store/voiceSlice";
import {useDispatch, useSelector} from "react-redux";
import NewsCommentsPopup from "../components/news/NewsCommentsPopup";
import {doGetNewsComments} from "../store/newsSlice";
import VoiceDisplayPic from "../components/voice/VoiceDisplayPic";
import {Transition, Dialog} from "@headlessui/react";

export default function NewsNewSingle() {

    const [toggleCommentPopup, setToggleCommentPopup] = useState(false);
    const [toggleSharePopup, setToggleSharePopup] = useState(false);
    const [copyBtnText, setCopyBtnText] = useState("Copy");

    const params = useParams();
    const dispatch = useDispatch();

    const {isFetching, data} = useGetNewsBySlugQuery(params.slug);
    const {showLoginPopup} = useSelector(state => state.voice);
    const {data: auth, isLoading: isLoadingUser} = useSelector(state => state.user);

    const handleToggleCommentPopup = (slug) => {
        if (auth?.user) {
            setToggleCommentPopup(prevState => !prevState)
            dispatch(doGetNewsComments({slug}))
        } else {
            dispatch(doToggleLoginPopup(!showLoginPopup))
        }
    }

    const handleCopyTextToClipBoard = () => {
        console.log(window.location.toString())
        // navigator.clipboard.writeText(window.location.toString())
        navigator.clipboard.writeText(window.location.toString());
        setCopyBtnText("Copied")

        setTimeout(() => setCopyBtnText("Copy"), 5000)
    }

    if (isFetching) {
        return (<SingleNewsLayout>
            <div className="bg-bColor-50 w-full">
                <ContentLoader
                    speed={2}
                    width={800}
                    height={700}
                    viewBox="0 0 800 700"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="10" rx="10" ry="10" width="800" height="50"/>
                    <rect x="0" y="80" rx="10" ry="10" width="800" height="500"/>
                    <rect x="0" y="610" rx="4" ry="4" width="350" height="9"/>
                    <rect x="0" y="630" rx="3" ry="3" width="300" height="6"/>
                </ContentLoader>
                {/*<PageTransitionLoader size={28}/>*/}
                {/*<span className="text-sm">LOADING</span>*/}
            </div>
        </SingleNewsLayout>)
    }

    if (!isFetching && !data) {
        return (<SingleNewsLayout>
            <div>News article doesn't exists.</div>
        </SingleNewsLayout>)
    }

    if (!isFetching && data) {
        return (
            <SingleNewsLayout>
                <Helmet>
                    <meta property="og:title" content={`${data.title}`}/>
                    <meta property="og:url" content={`https://news.findallnepal.com/${data.slug}`}/>
                    <link rel="canonical" href={`https://news.findallnepal.com/${data.slug}`}/>
                    <meta name="description" content={data.summary || ""}/>
                    <meta property="og:description" content={data.summary || ""}/>
                    <meta property="og:url" content={`https://news.findallnepal.com/${data.slug}`}/>
                    <meta property="og:image" content={data.feautredImage?.path || ""}/>
                    <title>{`${data.title}`}</title>
                </Helmet>
                <article>
                    <header>
                        <h1 className="text-3xl">{data.title}</h1>
                        <div className="flex justify-between items-center mt-4 text-sm">
                            <div className="text-bColor">
                                {(data.sourceTitle &&
                                    <span>Source:<a href={data.sourceLink || ""}>{data.sourceTitle}</a></span>
                                )}
                            </div>
                            <span>{DateTime.fromISO(data.publishedTime).toFormat("yyyy-LL-dd hh:mm") || "N/A"}</span>
                        </div>
                    </header>
                    <div>
                        <img src={`${data.featuredImage.path}`} alt=""
                             className="max-w-7xl w-full mt-5  mb-10 pointer-events-none w-full" height={384}/>
                        <div className="text-lg leading-relaxed" dangerouslySetInnerHTML={{__html: data.detail}}/>
                    </div>

                    {/*share this post section************************************************************************************/}
                    <div className="flex justify-end mt-8">
                        <button onClick={() => setToggleSharePopup(true)}
                                className="rounded-md px-4 py-2 bg-blue-100 text-blue-900 flex items-center justify-center space-x-2 hover:bg-blue-200">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path
                                    d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"/>
                            </svg>
                            <span>Share</span>
                        </button>
                    </div>
                    {/*share this popup section************************************************************************************/}
                    <Transition appear show={toggleSharePopup} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={() => setToggleSharePopup(false)}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25"/>
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel
                                            className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                                            <Dialog.Title as="h3" className="text-lg  uppercase text-gray-900">
                                                Share This
                                            </Dialog.Title>
                                            <div className="mt-4 space-y-4">
                                                <div className="flex gap-2 w-full">
                                                    <input type="text" readOnly value={window.location.toString()}
                                                           onFocus={(event) => event.target.select()}
                                                           className="bg-slate-200 px-4 py-2 w-full cursor-text rounded-md"/>
                                                    <button title="Copy link to clipboard"
                                                            onClick={handleCopyTextToClipBoard}
                                                            className="w-28 w-full bg-slate-300 px-4 rounded-md hover:bg-slate-200 flex items-center justify-center space-x-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4"
                                                             viewBox="0 0 20 20" fill="currentColor">
                                                            <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"/>
                                                            <path
                                                                d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"/>
                                                        </svg>
                                                        <span>{copyBtnText}</span>
                                                    </button>
                                                </div>
                                                <div className="text-white space-x-2">
                                                    <a className="p-3 bg-blue-600 rounded-md inline-block"
                                                       title="Facebook"
                                                       href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.toString()}`}
                                                       target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                             fill="none" viewBox="0 0 24 24">
                                                            <path fill="currentColor"
                                                                  d="M9.198 21.5h4v-8.01h3.604l.396-3.98h-4V7.5a1 1 0 011-1h3v-4h-3a5 5 0 00-5 5v2.01h-2l-.396 3.98h2.396v8.01z"
                                                            />
                                                        </svg>
                                                    </a>
                                                    <a className="p-3 bg-sky-500 rounded-md inline-block"
                                                       title="Twitter"
                                                       href={`https://twitter.com/intent/tweet?text=${window.location.toString()}`}
                                                       target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                             fill="none" viewBox="0 0 24 24">
                                                            <path fill="currentColor" fillRule="evenodd"
                                                                  d="M8 3a2 2 0 012 2v3h6a2 2 0 110 4h-6v2a3 3 0 003 3h3a2 2 0 110 4h-3a7 7 0 01-7-7V5a2 2 0 012-2z"
                                                                  clipRule="evenodd"
                                                            ></path>
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>

                </article>
                {/*comments section*/}
                <section id="comments" className="mt-16">
                    <h3 className="bg-bColor text-xl font-bold text-white px-5 py-4 my-4 rounded-md">
                        Comments on this news article ({data.commentCount})
                    </h3>

                    {data.commentCount <= 0 &&
                        <div className="my-4">
                            <span>Be the first one to comment.</span>
                        </div>
                    }

                    {!auth?.user &&
                        <button onClick={handleToggleCommentPopup}
                                className="my-4 text-lg bg-sky-50 border border-blue-300 rounded-md p-4 flex justify-center items-center w-full">
                            <img src="/assets/info.svg" alt="" className="w-6 h-6 inline-block mr-2"/>
                            <div className="text-sky-900">
                                Please <span className="text-bColor">Login</span> to comment.
                            </div>
                        </button>
                    }

                    <div className="space-y-4">

                        {(data.commentCount > 5) &&
                            <button className="hover:underline text-sm" title="View all comments"
                                    onClick={() => handleToggleCommentPopup(data.slug)}>
                                View all comments
                            </button>
                        }
                        {(data.comments.length > 0) && data.comments.map(item => {
                            return (
                                <div className="space-y-2" key={item.id}>
                                    <div className="flex items-start gap-1 relative">
                                        <img src={item?.commenter?.image?.path || ""} alt="" height={28} width={28}
                                             className="h-7 w-7 rounded-full"/>
                                        <div className="w-full text-sm">
                                            <div className="rounded-md bg-gray-100 px-3 py-2">
                                                <div className="flex items-center space-x-2 text-sm">
                                                    <a href={item.commenter.profile} className="font-bold"
                                                       target="_blank"
                                                       rel="noreferrer">
                                                        {item.commenter.name}
                                                    </a>
                                                    <span className="w-[3px] h-[3px] bg-gray-400 rounded-full"/>
                                                    <span className="font-light text-gray-500 text-xs">
                                                            {item.datetime_human}
                                                        </span>
                                                </div>
                                                <span className="break-all text-gray-600 tracking-wide">
                                                        {item.comment}
                                                    </span>
                                            </div>
                                            <div className="space-x-1">
                                                <button className="px-2 py-0.5 hover:underline"
                                                        onClick={() => handleToggleCommentPopup(item.owner.slug)}>
                                                    {item.isLiked ? "Unlike" : "Like"}
                                                </button>
                                                <button className="px-2 py-0.5"
                                                        onClick={() => handleToggleCommentPopup(item.owner.slug)}>
                                                    Reply
                                                </button>
                                            </div>
                                        </div>
                                        {item.likes
                                            ?
                                            <div
                                                className="flex justify-center items-center space-x-1 absolute right-2 top-2">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="h-5 w-5 rounded-full bg-gray-200 p-0.5"
                                                     viewBox="0 0 24 24" stroke={item.isLiked ? "" : "currentColor"}
                                                     strokeWidth={item.isLiked ? "" : "2"}
                                                     fill={item.isLiked ? "#92278f" : "transparent"}>
                                                    <path
                                                        d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"/>
                                                </svg>
                                                <div
                                                    className={`leading-none text-xs ${item.isLiked ? "text-bColor" : "text-inherit"}`}>
                                                    {item.likes ? millify(item.likes) : ""}
                                                </div>
                                            </div>
                                            : ""
                                        }
                                    </div>
                                </div>
                            )
                        }).reverse()
                        }
                        {auth.user &&
                            <div className="gap-1 flex items-start">
                                <VoiceDisplayPic/>
                                <div className="space-y-4 flex-grow">
                            <span className="bg-gray-100 p-2 block rounded-md text-gray-400 text-sm cursor-text"
                                  onClick={() => handleToggleCommentPopup(data.slug)}
                            > Write your comment </span>
                                </div>
                            </div>
                        }
                    </div>
                </section>
                <NewsCommentsPopup slug={data.slug} setToggleCommentPopup={setToggleCommentPopup}
                                   toggleCommentPopup={toggleCommentPopup}/>
            </SingleNewsLayout>
        )
    }

}

function SingleNewsLayout({children}) {

    return (
        <main className="relative mt-6 flex-grow">
            <div className="max-w-1170 mx-auto w-full">
                {/*<div className="flex space-x-10 items-start">*/}
                <section className="bg-white p-8" id="main-article">
                    {/*main article*/}
                    {children}
                </section>
                {/*</div>*/}
            </div>
        </main>
    )
}
