import {Link} from "react-router-dom";
import MainFooter from "../components/MainFooter";
import Accordion from "../components/Accordion";
import {useState} from "react";
import DirectionDialog from "../components/DirectionDialog";

export default function UnClaimed({data}) {

    let [isOpen, setIsOpen] = useState(false)

    const handleDirectionDialog = () => {
        setIsOpen(prevState => !prevState)
    }

    return (
        <div>
            <main>
                <section id="cta-banner">
                    <div className="flex justify-center items-center py-2 space-x-10 max-w-2xl mx-auto text-sm">
                        <p className="font-bold">
                            Expand
                            <span className="font-normal"> your business with </span>
                            Zero Investment
                        </p>
                        <Link to="#" className="w-40 py-2 px-8 bg-red-600 text-white text-center rounded-md">
                            Apply Now
                        </Link>
                    </div>
                </section>
                <section id="hero">
                    <div className="relative h-screen bg-no-repeat bg-repeat-y"
                         style={{backgroundImage: "url('/assets/images/unclaimed_bg.png')", backgroundSize: "100%"}}>
                        {/*<img src="" alt="" className="absolute top-0 bottom-0 left-0 right-0"/>*/}
                        <div className="absolute top-0 bottom-0 left-0 right-0 opacity-70 bg-black"/>
                        <div className="hero-wave">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                                 preserveAspectRatio="none">
                                <path
                                    d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                                    className="hero-wave-fill"/>
                            </svg>
                        </div>
                        <div className="flex justify-center items-center flex-col z-10 relative h-full text-white">
                            <img src={data?.logo?.path || "/assets/images/no-image.png"} alt=""
                                 className="border-8 border-gray-400 rounded-full w-36 h-36 mb-8"/>
                            <h1 className="text-5xl font-bold mb-3 max-w-4xl text-center" title={data?.name}>
                                {data?.name.length > 80 ? data?.name.slice(0, 80) + "..." : data?.name}
                            </h1>
                            <span className="text-xl max-w-4xl ">
                                {`${data?.address?.nagar_data?.name}, ${data?.address?.district_data?.name}, ${data?.address?.state_data?.name}, ${data?.address?.country_data?.name}`}
                            </span>
                            <div className="flex text-gray-900 mt-8 space-x-1">
                                <div className="py-3 px-5 rounded-md bg-white font-light flex">
                                    <img src="/assets/images/purple_phone.svg" alt="" className="mr-4"/>
                                    <div className="space-x-1">
                                        {data?.phone ? data?.phone?.map(item => <span
                                            key={item.id}>{item.value}</span>) : <span>-- ------</span>}
                                    </div>
                                </div>
                                <button to="#" className="py-3 px-5 rounded-md bg-white flex font-medium block" onClick={handleDirectionDialog}>
                                    <img src="/assets/images/purple_pin.svg" alt="" className="mr-4"/>
                                    <span>Get Direction</span>
                                </button>
                                <Link to="#" className="block py-3 px-5 rounded-md bg-bColor text-white flex">
                                    <span className="mr-4">CREATE NOW</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M13 5l7 7-7 7M5 5l7 7-7 7"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mt-16">
                    <div className="max-w-1170 mx-auto">
                        <div className="mb-10">
                            <h2 className="font-bold text-5xl mb-2">Your Business Profile</h2>
                            <span className="text-xl font-light">will look like this</span>
                        </div>
                        <img src="/assets/images/profile_preview.png" alt="" className="w-full"/>
                    </div>
                </section>
                <div className="border-t border-gray-200"/>
                <section className="mt-16">
                    <div className="max-w-1170 mx-auto">
                        <div className="mb-10">
                            <h2 className="font-bold text-5xl mb-2">Browse some websites</h2>
                            <span className="text-xl font-light">for your reference</span>
                        </div>
                        <div className="grid gap-4 grid-cols-3 text-center">
                            {data?.sample.map(current => {
                                return (
                                    <Link to={current.slug} key={current.slug}>
                                        <article
                                            className="rounded-3xl bg-gray-100 flex justify-center items-center flex-col w-full">
                                            <img src={current?.featuredImage?.path}
                                                 alt="" loading="lazy" className="rounded-t-3xl w-full h-52"/>
                                            <img src={current?.logo?.path} alt=""
                                                 className="w-24 -mt-11 rounded-full"/>
                                            <div
                                                className="rounded-b-3xl flex justify-center items-center flex-col py-6 px-8">
                                                <h3 className="text-xl font-bold">{current?.name}</h3>
                                                <span title={current?.address}>
                                                    {current?.address.length > 30 ? current?.address.slice(0, 30) + "..." : current?.address}
                                                </span>
                                            </div>
                                        </article>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                </section>
                <section className="mt-16">
                    <div>
                        <img src="/assets/images/unclaimed_silder.png" alt=""/>
                    </div>
                </section>
                <section className="py-20 bg-gray-100">
                    <h2 className="font-bold text-5xl text-center mb-12">Benefits of Creating Profile</h2>
                    <div className="flex space-x-6 max-w-1170 mx-auto">
                        <article className="bg-white px-4 py-10 text-center">
                            <div className="h-24">
                                <img src="/assets/images/get_discovered.svg" alt="" className="w-14 mb-12 mx-auto"/>
                            </div>
                            <h3 className="font-bold text-xl mb-7">Get Discovered</h3>
                            <p className="leading-normal font-light text-lg">
                                Improve your online presence with us and get discovered by potential Customers online.
                                You will be able to increase your brand awareness. The more you are on internet, chances
                                of your brand getting recognized are high.
                            </p>
                        </article>
                        <article className="bg-white px-4 py-10 text-center">
                            <div className="h-24">
                                <img src="/assets/images/improve_seo.svg" alt="" className="mb-12 mx-auto"/>
                            </div>
                            <h3 className="font-bold text-xl mb-7">Improve SEO</h3>
                            <p className="leading-normal font-light text-lg">
                                SEO is the technique of using your web content in such a way that search engines find it
                                useful & rank it higher in relevant search results. Our listings will give it extra
                                validation to gain the trust of search engines such as Google and Bing
                            </p>
                        </article>
                        <article className="bg-white px-4 py-10 text-center">
                            <div className="h-24">
                                <img src="/assets/images/build_reputation.svg" alt="" className="w-14 mb-12 mx-auto"/>
                            </div>
                            <h3 className="font-bold text-xl mb-7">Build Reputation</h3>
                            <p className="leading-normal font-light text-lg">
                                With all of the available techs, today’s buyers are exceedingly cautious about which
                                vendors they trust online. This is where we can be extremely helpful. Our platform is
                                often seen as a reliable source for finding high-quality, trustworthy businesses.
                            </p>
                        </article>
                        <article className="bg-white px-4 py-10 text-center">
                            <div className="h-24">
                                <img src="/assets/images/refine_list.svg" alt="" className="w-14 mb-12 mx-auto"/>
                            </div>
                            <h3 className="font-bold text-xl mb-7">Refine Listing</h3>
                            <p className="leading-normal font-light text-lg">
                                Claiming this business profile will get you access to all the tools necessary to create
                                a full fledged profile page. That wil help you to reach your customers and will
                                dramatically improve the seo of your site, if any.
                            </p>
                        </article>
                    </div>
                </section>
                <section className="mt-16">
                    <div className="max-w-3xl mx-auto">
                        <div className="mb-10 flex flex-col justify-center items-center">
                            <h2 className="font-bold text-5xl mb-2">Create Your Business Profile</h2>
                            <span className="text-xl font-light">Follow the below three steps</span>
                        </div>
                        <div className="tracking-normal space-y-3">
                            <Accordion
                                title="Create Account"
                                content="Create an account or Login. Go to the business profile and click on claim button. You will be redirected to your account dashboard. Click on 'Request Access' button"
                            />
                            <Accordion
                                title="Send Request"
                                content="Create an account or Login. Go to the business profile and click on claim button. You will be redirected to your account dashboard. Click on 'Request Access' button"
                            />
                            <Accordion
                                title="Confirm Ownership"
                                content="Create an account or Login. Go to the business profile and click on claim button. You will be redirected to your account dashboard. Click on 'Request Access' button"
                            />
                        </div>

                    </div>
                </section>
            </main>
            <DirectionDialog handleDirectionDialog={handleDirectionDialog} isOpen={isOpen}  setIsOpen={setIsOpen}/>
            <MainFooter/>
        </div>
    )
}