import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import EducationFooter from "../../components/education/EducationFooter";
import {Link, useParams} from "react-router-dom";
import routes from "../../constants/routes";
import {useGetOrgEventsQuery} from "../../services/directoryApi";
import DOMPurify from "dompurify";
import {DateTime} from "luxon";
import {Helmet} from "react-helmet-async";

export default function Events({data}) {


    const param = useParams();

    const {data: eventList, isFetching} = useGetOrgEventsQuery(param.slug);


    return (
        <>
            <Helmet>
                <meta property="og:title" content={`Events | ${data.name}`}/>
                <meta property="og:url" content={`https://findallnepal.com/${data.slug}/events`}/>
                <link rel="canonical" href={`https://findallnepal.com/${data.slug}/events`}/>
                <title>{`Events | ${data.name}`}</title>
            </Helmet>
            <section id="hero">
                <EducationTopMenu data={data}/>
                <EducationMainMenuWithBackground
                    background={data.featuredImage.path || "/assets/images/edu_events_bg.png"} height="25rem"
                    data={data}/>
            </section>
            <main className="my-10">
                <div className="max-w-1170 mx-auto">
                    <h2 className="text-3xl font-bold mb-6 text-black">EVENTS</h2>
                    <div className="grid grid-cols-2 gap-2">
                        {(isFetching) && <div>Loading..</div>}
                        {(!isFetching && eventList?.organizationWithEvents) &&
                            eventList?.organizationWithEvents?.events.map(current => {
                                return (
                                    <Link to={current.slug} key={current.slug}>
                                        <article className="bg-gray-100 p-4 flex h-full">
                                            <div
                                                style={{backgroundImage: `url(${current.featuredImage?.path || '/assets/images/event_thumb1.png'})`}}
                                                className="w-52 mr-6 bg-center"/>
                                            {/*<img src="" alt="" className="w-52 mr-6"/>*/}
                                            <div className="space-y-3 text-sm h-full flex flex-col justify-between">
                                                <h3 className="font-bold text-xl capitalize">{current.name}</h3>
                                                <div className="font-medium"
                                                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(current.desc, {USE_PROFILES: {html: true}})}}
                                                />
                                                <ul className="max-w-sm">
                                                    <li className="flex items-baseline">
                                                        <img className="inline-block w-3"
                                                             src="/assets/images/calender.svg"
                                                             alt=""/>
                                                        <span className="ml-2">
                                                            {DateTime.fromISO(current.from).toLocaleString({locale: 'en-gb'})} - {DateTime.fromISO(current.to).toLocaleString({locale: 'en-gb'})}
                                                        </span>
                                                    </li>
                                                    <li className="flex items-baseline">
                                                        <img className="inline-block w-3"
                                                             src="/assets/images/user_org.svg"
                                                             alt=""/>
                                                        <span className="ml-2 capitalize">{current.name}</span>
                                                    </li>
                                                    {/*<li className="flex items-baseline">*/}
                                                    {/*    <img className="inline-block w-3" src="/assets/images/time.svg"*/}
                                                    {/*         alt=""/>*/}
                                                    {/*    <span className="ml-2">10:00 AM - 5:00 PM</span>*/}
                                                    {/*</li>*/}
                                                    <li className="flex items-baseline">
                                                        <img className="inline-block w-2.5"
                                                             src="/assets/images/location_pin.svg" alt=""/>
                                                        <span className="ml-2 capitalize">{current.location}</span>
                                                    </li>
                                                </ul>
                                                <div className="flex space-x-2 items-center text-center">
                                        <span
                                            className="block py-2 bg-education rounded-md text-white flex-grow capitalize">
                                            {current.status}
                                        </span>
                                                    <span
                                                        className="block py-2  bg-gray-400 rounded-md text-white flex-grow">
                                            <img src="/assets/images/share_white.svg" alt=""
                                                 className="inline w-4 mr-2"/>
                                        Share
                                        </span>
                                                </div>
                                            </div>
                                        </article>
                                    </Link>
                                )
                            })
                        }
                        {(isFetching && (eventList?.organizationWithEvents.length === 0) ) &&
                            <div>No recent events.</div>
                        }
                    </div>
                </div>
            </main>
            <EducationFooter data={data}/>
        </>
    )
}