import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import {Link} from "react-router-dom";
import EducationSectionHeading from "../../components/education/EducationSectionHeading";
import Carousel from "react-multi-carousel";
import {SRLWrapper} from "simple-react-lightbox";
import 'react-multi-carousel/lib/styles.css';
import EducationFooter from "../../components/education/EducationFooter";
import DOMPurify from "dompurify";
import React, {useState} from "react";
import millify from "millify";
import DirectionDialog from "../../components/DirectionDialog";
import {Helmet} from "react-helmet-async";
import RatingAndReview from "../../components/RatingAndReview";


export default function SingleEducationHome({data}) {

    const responsive = {
        desktop: {breakpoint: {max: 3000, min: 0}, items: 2,},
    };

    const [descReadMore, setDescReadMore] = useState(false);


    let [isDirectionOpen, setIsDirectionOpen] = useState(false)

    const handleDirectionDialog = () => {
        setIsDirectionOpen(prevState => !prevState)
    }

    let cleanDescription = DOMPurify.sanitize(data.description, {USE_PROFILES: {html: true}});


    const SliderNavs = ({next, previous, goToSlide, ...rest}) => {
        // const {carouselState: {currentSlide}} = rest;
        return (
            <div className="absolute -top-20 right-0 space-x-3 z-10">
                <button
                    className="p-2.5 border border-education text-education rounded-md hover:text-white hover:bg-education"
                    onClick={() => previous()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"/>
                    </svg>
                </button>
                <button
                    className="p-2.5 border border-education text-education rounded-md hover:text-white hover:bg-education"
                    onClick={() => next()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                    </svg>
                </button>
            </div>
        );
    };

    const TestimonialsNavs = ({next, previous, goToSlide, ...rest}) => {
        // const {carouselState: {currentSlide}} = rest;
        return (
            <div className="absolute -top-20 right-0 space-x-3 z-10">
                <button
                    className="p-2.5 border border-white text-white rounded-md hover:text-education hover:bg-white"
                    onClick={() => previous()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"/>
                    </svg>
                </button>
                <button
                    className="p-2.5 border border-white text-white rounded-md hover:text-education hover:bg-white"
                    onClick={() => next()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                    </svg>
                </button>
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`${data.name}`}/>
                <meta property="og:url" content={`https://findallnepal.com/${data.slug}`}/>
                <link rel="canonical" href={`https://findallnepal.com/${data.slug}`}/>
                <title>{`${data.name}`}</title>
            </Helmet>
            <section id="hero">
                <EducationTopMenu data={data}/>
                {/*<EducationMainMenuWithBackground background="/assets/images/education_slide.jpg" height="40rem" data={data}/>*/}
                <EducationMainMenuWithBackground
                    background={data.featuredImage.path || "/assets/images/edu_events_bg.png"} height="40rem"
                    data={data}/>
            </section>
            <section id="about">
                <div
                    className="px-10 pt-16 pb-6 -mt-16 rounded-lg max-w-1170 mx-auto border border-gray-100 relative border-t-0">
                    <div
                        className="bg-white rounded-lg education-cutout-mask absolute top-0 bottom-0 right-0 left-0"/>
                    <div
                        className="absolute rounded-full -top-10 bg-white p-5 h-20 w-20 left-1/2 transform -translate-x-1/2 border border-gray-200 flex items-center justify-center flex-col">
                        <img src="/assets/images/yellow_star.png" alt="" className="mb-1"/>
                        <span>4.5</span>
                    </div>
                    <div className="space-x-4 flex relative z-10">
                        <div
                            className="px-7 py-3 flex justify-center items-center border border-gray-100 rounded-lg w-1/4">
                            <img src="/assets/images/blue_edit.svg" width={36} height={36} alt="" className="mr-4"/>
                            <Link to="#">
                                <span className="text-sm">Admission Open</span>
                            </Link>
                        </div>
                        <div
                            className="px-7 py-3 flex justify-center items-center border border-gray-100 rounded-lg w-1/4">
                            <img src="/assets/images/blue_calender.svg" width={36} height={36} alt=""
                                 className="mr-4"/>
                            <Link to={`/${data.slug}/events`}>
                                <span className="text-sm">Upcoming Events</span>
                            </Link>
                        </div>
                        <div
                            className="px-7 py-3 flex justify-center items-center border border-gray-100 rounded-lg w-1/4">
                            <img src="/assets/images/blue_time.svg" width={36} height={36} alt="" className="mr-4"/>
                            <Link to="#">
                                <span className="text-sm">Opening Hours</span>
                            </Link>
                        </div>
                        <button onClick={handleDirectionDialog}
                                className="px-7 py-3 flex justify-center items-center border border-gray-100 rounded-lg w-1/4">
                            <img src="/assets/images/blue_map.svg" width={36} height={36} alt="" className="mr-4"/>
                            <span className="text-sm">Get Direction</span>
                        </button>
                    </div>
                </div>
                <div className="max-w-1170 mx-auto mt-16">
                    <div className="flex items-start">
                        <div className="w-1/2">
                            <EducationSectionHeading text="About us"/>
                            <span className="block text-5xl font-bold text-black mb-6">WHO WE ARE ?</span>
                            <div className={`${descReadMore ? "" : "h-72"} overflow-hidden`}>
                                <div className="leading-relaxed inline"
                                     dangerouslySetInnerHTML={{__html: cleanDescription}}/>
                            </div>
                            {!descReadMore &&
                                <button className="education-themed-btn" onClick={() => setDescReadMore(true)}>
                                    Read More
                                </button>
                            }
                        </div>
                        <img src="/assets/images/about_building.png" alt="" className="ml-12" width="50%"/>
                    </div>
                </div>
            </section>
            <section id="academic-programmes" className="mt-16">
                <div className="bg-gray-100 py-16">
                    <div className="max-w-1170 mx-auto ">
                        <div>
                            <h2 className="font-bold text-4xl text-education mb-6">Academic Programmes</h2>
                            <div className="relative">
                                <Carousel responsive={responsive} slidesToSlide={1} infinite className="-mx-2"
                                          customButtonGroup={<SliderNavs/>} arrows={false}
                                          renderButtonGroupOutside={true}>
                                    {data.faculty.map(current => {
                                        return (
                                            <article
                                                className="relative flex justify-center items-center flex-col text-white mx-2"
                                                key={current.slug}>
                                                <img src={current.image?.path} alt="" className="w-full h-72"/>
                                                <div className="absolute inset-0 rounded-md bg-black opacity-60"/>
                                                <div
                                                    className="absolute inset-0 flex items-center justify-center flex-col p-20 h-full">
                                                    <h3 className="mb-4 text-3xl font-bold z-10 relative">{current.title}</h3>
                                                    <div className="text-sm z-10 relative text-center"
                                                         dangerouslySetInnerHTML={{__html: current.description}}/>
                                                </div>
                                            </article>
                                        )
                                    })}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="cta-banner">
                {/*    <div className="flex justify-center items-center py-8 space-x-10 max-w-2xl mx-auto text-sm">*/}
                {/*        <p>*/}
                {/*            <span className="font-bold">*/}
                {/*                Join over 34,000+ UX Designers,</span> consectetur adipisicing elit, sed*/}
                {/*            do eiusmod tempor incididunt ut*/}
                {/*            labore et dolore magna aliquat enim ad.*/}
                {/*        </p>*/}
                {/*        <Link to="#" className="w-52 py-2 px-8 bg-red-600 text-white text-center rounded-md">*/}
                {/*            Apply Now*/}
                {/*        </Link>*/}
                {/*    </div>*/}
            </section>
            <section id="facilities" className="mb-16">
                <div className="relative overflow-y-hidden">
                    <img src={data.featured_slider?.[0]?.image?.path || "/assets/images/bg_building.png"} alt=""
                         className="absolute inset-0 w-full h-full"/>
                    <div className="relative z-10 flex">
                        <div className="w-1/2"/>
                        <div className="w-1/2 p-14 relative text-white">
                            <div className="absolute top-0 bottom-0 right-0 left-0 bg-education opacity-90"/>
                            <div className="z-10 relative">
                                <h2 className="font-bold text-4xl mb-10">Our Facilities</h2>
                                <div className="space-y-6">
                                    {data.featured_slider?.[0]?.items?.map(current => {
                                        return <article className="flex items-start" key={current.slug}>
                                            <img src="/assets/images/play_button.png" alt=""
                                                 className="mr-8 pointer-events-none"/>
                                            <div>
                                                <h3 className="text-xl font-bold mb-4">{current.name}</h3>
                                                <p className="text-sm max-w-sm">
                                                    {current.desc.slice(0, 150)} {current.desc.length > 150 ? "..." : ""}
                                                </p>
                                            </div>
                                        </article>

                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="messages" className="mb-16">
                <div className="max-w-1170 mx-auto">
                    <EducationSectionHeading text="Message"/>
                    <div className="relative">
                        <Carousel responsive={{desktop: {breakpoint: {max: 3000, min: 0}, items: 1,}}} slidesToSlide={1}
                                  infinite arrows={false} customButtonGroup={<SliderNavs/>}
                                  renderButtonGroupOutside={true}
                        >
                            {data.feature_message.map(current => {
                                return (
                                    <article key={current.slug}>
                                        <h3 className="block text-5xl text-black mb-8 uppercase">{current.message} <span
                                            className="font-bold">{current.from}</span></h3>
                                        <div className="flex items-start w-full h-96">
                                            <img src={current.image?.path || ""} alt="" className="mr-10 h-96 w-96"/>
                                            <div className="text-sm flex flex-col justify-between h-full">
                                                <div className="flex-grow text-xl"
                                                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(current.desc, {USE_PROFILES: {html: true}})}}/>
                                                {/*<div className="flex flex-col">*/}
                                                {/*    <span className="font-bold">Rabindra Khatun</span>*/}
                                                {/*    <span>Principal</span>*/}
                                                {/*    <span>Himalayan Engineering College</span>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </article>
                                )
                            })}
                        </Carousel>
                    </div>
                </div>
            </section>
            <section id="featured-images" className="mb-10">
                <div className="bg-gray-100 py-16">
                    <div className="max-w-1170 mx-auto ">
                        <div>
                            <EducationSectionHeading text="featured"/>
                            <span className="block text-5xl font-bold text-black mb-8">IMAGES</span>
                            <SRLWrapper>
                                <div className="relative">
                                    <Carousel responsive={responsive} slidesToSlide={1} infinite className="-mx-2"
                                              customButtonGroup={<SliderNavs/>} arrows={false}
                                              renderButtonGroupOutside={true}
                                    >
                                        {data.featuredGallery?.map(current => {
                                            return <img src={current.thumb} alt="" key={current.slug}
                                                        className="px-2 w-full h-80 rounded-md cursor-pointer"/>
                                        })}
                                    </Carousel>
                                </div>
                            </SRLWrapper>
                        </div>
                    </div>
                </div>
            </section>
            <section id="statistics" className="mb-10">
                <div className="max-w-1170 mx-auto">
                    <ul className="flex justify-between items-center">
                        <li>
                            <strong
                                className="text-education font-bold text-4xl">{millify(data.likes)}{(data.likes > 10) && "+"}</strong>
                            <span className="text-gray-400 text-sm block">Likes</span>
                        </li>
                        <li>
                            <strong
                                className="text-education font-bold text-4xl">{millify(data.views)}{(data.views > 10) && "+"}</strong>
                            <span className="text-gray-400 text-sm block">Views</span>
                        </li>
                        <li>
                            <strong
                                className="text-education font-bold text-4xl">{millify(data.comments)}{(data.comments > 10) && "+"}</strong>
                            <span className="text-gray-400 text-sm block">Comments</span>
                        </li>
                        <li>
                            <strong
                                className="text-education font-bold text-4xl">{millify(data.searches)}{(data.searches > 10) && "+"}</strong>
                            <span className="text-gray-400 text-sm block">Searches</span>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="testimonials">
                <div className="bg-gray-100 py-16 relative">
                    <div className="absolute top-0 bottom-0 right-0 left-0 bg-fixed"
                         style={{backgroundImage: "url(/assets/images/education_slide.jpg)"}}/>
                    <div className="absolute top-0 bottom-0 right-0 left-0 bg-education opacity-90"/>
                    <div className="max-w-1170 mx-auto relative z-10 text-white">
                        <div>
                            <h2 className="text-xl font-medium pb-6 mb-6 border-b-4 border-white uppercase inline-block">testimonials</h2>
                            <span className="block text-5xl font-bold mb-8">What Students Say?</span>
                            <div className="relative">
                                <Carousel responsive={responsive} slidesToSlide={1} infinite className="-mx-2"
                                          customButtonGroup={<TestimonialsNavs/>} arrows={false}
                                          renderButtonGroupOutside={true}
                                >
                                    {data.testimonial?.[0].testimonialItems.map(current => {
                                        return (
                                            <article className="flex items-start mx-2 py-8 h-full" key={current.slug}>
                                                <div className="relative mr-14">
                                                    <img src={current.icon.path} alt="" width={120} height={120}
                                                         className="rounded-lg max-w-md"/>
                                                    <img src="/assets/images/quote.png" alt="" width={48} height={48}
                                                         className="absolute -top-7 -right-7"/>
                                                </div>
                                                <div className="flex flex-col space-y-6 h-full">
                                                    <p className="flex-grow">
                                                        {current.description.slice(0, 200)} {(current.description.length > 200) ? "..." : ""}
                                                    </p>
                                                    <div className="capitalize">
                                                        <strong className="block">{current.name}</strong>
                                                        <span>{current.position}</span>
                                                    </div>
                                                </div>
                                            </article>
                                        )
                                    })}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="reviews">
                <div className="bg-gray-100 py-16 relative bg-gray-100 bg-no-repeat bg-right-top"
                     style={{backgroundImage: "url(/assets/images/review_bg.png)", backgroundSize: "cover", backgroundAttachment: "fixed"}}>
                    <div
                        className="absolute top-0 bottom-0 right-0 left-0 bg-gradient-to-r from-gray-300 via-gray-100 to-transparent"/>
                    <div className="relative z-10 mx-auto max-w-1170">
                        <EducationSectionHeading text="Ratings & Reviews"/>
                        {/*<h2 className="text-center text-4xl font-bold">Ratings And Reviews</h2>*/}
                        <RatingAndReview data={data}/>
                    </div>
                </div>
            </section>

            <section id="partners">
                <div className="max-w-1170 mx-auto py-12">
                    <h2 className="text-center font-bold text-xl mb-6 text-gray-500">SUPPORTED BY</h2>
                    <div className="flex justify-center items-center space-x-10">
                        <img src="/assets/images/edu_supporter_1.png" alt="" className="w-32"/>
                        <img src="/assets/images/edu_supporter_2.png" alt="" className="w-32"/>
                        <img src="/assets/images/edu_supporter_3.png" alt="" className="w-32"/>
                    </div>
                </div>
            </section>
            <EducationFooter data={data}/>
            <DirectionDialog handleDirectionDialog={handleDirectionDialog} isOpen={isDirectionOpen}
                             setIsOpen={setIsDirectionOpen}/>
        </>
    )
}

// <div className="max-w-1170 mx-auto relative z-10">
//     <EducationSectionHeading text="Comments/Reviews"/>
//     <span className="block text-5xl font-bold text-black mb-16 uppercase">Write your Comment</span>
//     <div className="flex">
//         <div className="w-1/2">
//             <form onSubmit={(event) => event.preventDefault()} className="space-y-4">
//                 <input type="text" placeholder="Enter your name"
//                        className="block rounded-md p-3 w-full border-2 border-gray-300"/>
//                 <input type="email" placeholder="Enter your email address"
//                        className="block rounded-md p-3 w-full border-2 border-gray-300"/>
//                 <textarea rows={5} placeholder="Write your comments/reviews"
//                           className="block rounded-md p-3 w-full border-2 border-gray-300"/>
//                 <button type="submit"
//                         className="px-10 py-2.5 bg-education text-white rounded-md">Submit
//                 </button>
//             </form>
//         </div>
//         <div className="w-1/2"/>
//     </div>
// </div>
