import {current} from "@reduxjs/toolkit";

export default function EducationTopMenu({data}) {

    const social_media = data.social_media?.filter(current => current.url)

    return (
        <section id="top-menu" className="bg-education text-white p-2">
            <div className="max-w-1170 mx-auto flex justify-between text-sm">
                <div className="flex space-x-6">
                    <div className="flex items-center">
                        <img src="/assets/images/phone.svg" alt="" width={16} className="inline-block mr-2"/>
                        <div className="flex items-center">
                            {data.phone.length
                                ? data.phone.map((current, index) => {
                                    return <span className="mr-2" key={current.id}>
                                        <a href={`tel:${current.value}`}>
                                            {current.value} {(data.phone.length > 1 && index === 0) && ", "}
                                        </a>
                                    </span>
                                })
                                : <span>n/a</span>}

                        </div>
                    </div>
                    <div className="flex items-center">
                        <img src="/assets/images/mail.svg" alt="" width={16} className="inline-block mr-2"/>
                        <div className="flex items-center">
                            {data.email.length
                                ? data.email.map((current, index) => {
                                    return <span className="mr-2" key={current.id}>
                                            <a href={`mailto:${current.value}`}>
                                                {current.value} {(data.email.length > 1 && index === 0) && ", "}
                                            </a>
                                    </span>
                                })
                                : <span>n/a</span>}
                        </div>
                    </div>
                </div>
                <div className="flex items-center">
                    <span style={{lineHeight: "normal", marginRight: "1rem"}}>Follow us:</span>
                    {social_media.map((current, index) => {
                        return (
                            <a href={current.url} target="_blank" rel="noreferrer" key={index + Math.random()}>
                                <img src={`/assets/images/${current.type}.svg`} alt="" width={16}
                                className="inline-block mr-2"/>
                            </a>
                        )
                    })}

                </div>
            </div>
        </section>
    )
}