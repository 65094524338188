import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useDoPostReviewMutation} from "../services/directoryApi";

export default function RatingAndReview({data}) {

    const [userRating, setUserRating] = useState({value: 5, text: "5 Stars - chaotic good", comment: " "});

    const {data: userData, isLoading: userIsLoading} = useSelector(state => state.user);

    const [postReview, {data: postReviewData, isLoading, isSuccess, isError}] = useDoPostReviewMutation();

    const user_review = data.user_reviews[0]

    const {one, two, three, four, five, total, avg_rating} = data.ratings;

    const starPercentage = {
        one: total ? one / total * 100 + "%" : "0%",
        two: total ? two / total * 100 + "%" : "0%",
        three: total ? three / total * 100 + "%" : "0%",
        four: total ? four / total * 100 + "%" : "0%",
        five: total ? five / total * 100 + "%" : "0%",
    }

    const handleUserRating = (value, text) => {
        setUserRating((prevState) => ({...prevState, value, text}))
    }

    const handleCommentChange = (event) => {
        setUserRating({...userRating, comment: event.target.value})
    }

    const handleRatingReviewSubmit = (event) => {
        // console.log(userRating)
        event.preventDefault();
        postReview({_sp: data.uuid, _olo: 2, _io: userRating.comment, _ir: userRating.value, slug: data.slug})

    }


    return (
        <>
            {/*show ratings*/}
            {/*<div className="mx-auto max-w-1000">*/}
            <div className="mb-2 bg-white rounded-2xl p-8 w-full flex gap-16">
                <div className="">
                    <div className="tracking-wide ">
                        <div className="text-6xl inline-block mr-2 relative">
                            {/*<img src="/assets/images/yellow_star.png" alt="" className="absolute -right-6 -top-4 w-6"/>*/}
                            <span>{avg_rating}</span>
                        </div>
                        <span className="text-slate-400"> out of 5 Stars</span>
                    </div>
                    <div className="Stars mt-1"
                         style={{"--rating": avg_rating, "--star-size": "3.3rem"}}
                         aria-label="Rating of this organization."/>
                    <div className="text-sm text-slate-400">
                        {total ? <span>{data.ratings?.total} Ratings</span>
                            : <span>No Ratings Yet</span>
                        }
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div className="flex">
                        <div className="flex items-center pointer-events-none">
                            <div className="flex gap-0.5 mr-4">
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                            </div>
                            <div className="h-2.5 w-40 bg-stone-200 mr-2 relative">
                                <div className="h-2.5 w-40 bg-yellow-400 mr-2 absolute"
                                     style={{width: starPercentage.five}}/>
                            </div>
                            <span className="text-xs">{five}</span>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex items-center pointer-events-none">
                            <div className="flex gap-0.5 mr-4">
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/white_star.png" alt="" className="w-4"/>
                            </div>
                            <div className="h-2.5 w-40 bg-stone-200 mr-2 relative">
                                <div className="h-2.5 w-40 bg-yellow-400 mr-2 absolute"
                                     style={{width: starPercentage.four}}/>
                            </div>
                            <span className="text-xs">{four}</span>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex items-center pointer-events-none">
                            <div className="flex gap-0.5 mr-4">
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/white_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/white_star.png" alt="" className="w-4"/>
                            </div>
                            <div className="h-2.5 w-40 bg-stone-200 mr-2 relative">
                                <div className="h-2.5 w-40 bg-yellow-400 mr-2 absolute"
                                     style={{width: starPercentage.three}}/>
                            </div>
                            <span className="text-xs">{three}</span>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex items-center pointer-events-none">
                            <div className="flex gap-0.5 mr-4">
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/white_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/white_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/white_star.png" alt="" className="w-4"/>
                            </div>
                            <div className="h-2.5 w-40 bg-stone-200 mr-2 relative">
                                <div className="h-2.5 w-40 bg-yellow-400 mr-2 absolute"
                                     style={{width: starPercentage.two}}/>
                            </div>
                            <span className="text-xs">{two}</span>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex items-center pointer-events-none">
                            <div className="flex gap-0.5 mr-4">
                                <img src="/assets/images/yellow_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/white_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/white_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/white_star.png" alt="" className="w-4"/>
                                <img src="/assets/images/white_star.png" alt="" className="w-4"/>
                            </div>
                            <div className="h-2.5 w-40 bg-stone-200 mr-2 relative">
                                <div className="h-2.5 w-40 bg-yellow-400 mr-2 absolute"
                                     style={{width: starPercentage.one}}/>
                            </div>
                            <span className="text-xs">{one}</span>
                        </div>
                    </div>
                </div>
            </div>
            {/*</div>*/}

            {/*users review and rating*/}
            {data.is_rated &&
                <div className="bg-white rounded-2xl py-4 px-8 w-full mb-2">
                    <h3 className="font-bold text-gray-500 mb-2">My review</h3>
                    <hr className="mb-2"/>

                    <div className="mt-4">
                        <div className="flex items-center gap-2 text-xs text-gray-600"
                             title={`${user_review.rating} stars`}>
                            <div className="Stars"
                                 style={{
                                     "--rating": user_review.rating || 0,
                                     "--star-size": "1.5rem"
                                 }}
                                 aria-label="Users rating for this profile."/>
                            <span>{`${user_review.rating || 0} stars out of 5`}</span>
                        </div>
                        <p className="mt-4">
                            {user_review.comment || "No review"}
                        </p>

                    </div>
                </div>
            }

            {/*list reviews*/}
            {data.top_reviews.length
                // ? <div className="mx-auto max-w-1000">
                ? <div className="mb-2 flex space-x-9">
                    <div className="bg-white rounded-2xl py-4 px-8 w-full">
                        <h3 className="text-gray-500 mb-2">Top Reviews</h3>
                        <hr className="mb-2"/>
                        <div className="divide-y divide-gray-200">
                            {data.top_reviews.map(current => {
                                return (
                                    <article className="flex gap-4 py-4" key={current.id}>
                                        <img alt=""
                                             src={current.commenter?.image?.path || "/assets/images/user-placeholder.png"}
                                             className="rounded-full w-10 h-10 bg-gray-600"/>
                                        <div className="flex flex-col items-start text-gray-600 space-y-3">
                                            <div className="">
                                                <div className="space-x-2">
                                                    <span className="font-bold">{current.commenter?.name}</span>
                                                    <span
                                                        className="text-xs text-gray-400">on {current.datetime}</span>
                                                </div>
                                                <div className="flex items-center gap-2 text-xs text-gray-600"
                                                     title={`${current.rating || 0} stars`}>
                                                    <div className="Stars"
                                                         style={{
                                                             "--rating": current.rating || 0,
                                                             "--star-size": "1.5rem"
                                                         }}
                                                         aria-label="Rating of this organization."/>
                                                    <span>{`${current.rating || 0} stars`}</span>
                                                </div>
                                                <p className="mt-1">{current.comment}</p>
                                            </div>
                                            {/*<button className="text-sm" title="Like this review">Like</button>*/}
                                        </div>
                                    </article>
                                )
                            })}
                        </div>

                    </div>
                </div>
                // </div>
                : ""
            }

            {/*write review and rate*/}
            {!data.is_rated
                ? <>
                    {(!userIsLoading && userData?.user)
                        ? <form className="bg-white rounded-2xl px-8 py-4 flex-grow"
                                onSubmit={handleRatingReviewSubmit}>
                            <h3 className="text-gray-500 mb-2">Rate And Review - <span
                                className={"font-bold"}>{data.name}</span></h3>
                            <hr className="mb-2"/>
                            {/*starsssss*/}
                            <div className="flex items-center space-x-4 mb-2">
                                <div
                                    className="flex space-x-1 flex-row-reverse justify-between w-44 text-4xl star-rating">
                                    <input type="radio" id="5-stars" name="rating" value="5" className="hidden"
                                           defaultChecked={true}
                                           onChange={() => handleUserRating(5, "5 Stars - chaotic good")}/>
                                    <label htmlFor="5-stars" className="cursor-pointer text-gray-300"
                                           title="Give 5 stars">&#9733;</label>

                                    <input type="radio" id="4-stars" name="rating" value="4" className="hidden"
                                           onChange={() => handleUserRating(4, "4 Stars - good")}/>
                                    <label htmlFor="4-stars" className="cursor-pointer text-gray-300 "
                                           title="Give 4 stars">&#9733;</label>

                                    <input type="radio" id="3-stars" name="rating" value="3" className="hidden"
                                           onChange={() => handleUserRating(3, "3 Stars - neutral")}/>
                                    <label htmlFor="3-stars" className="cursor-pointer text-gray-300 "
                                           title="Give 3 stars">&#9733;</label>

                                    <input type="radio" id="2-stars" name="rating" value="2" className="hidden"
                                           onChange={() => handleUserRating(2, "2 Stars - poor")}/>
                                    <label htmlFor="2-stars" className="cursor-pointer text-gray-300 "
                                           title="Give 2 stars">&#9733;</label>

                                    <input type="radio" id="1-star" name="rating" value="1" className="hidden"
                                           onChange={() => handleUserRating(1, "1 Star - very poor")}/>
                                    <label htmlFor="1-star" className="cursor-pointer text-gray-300 "
                                           title="Give 1 star">&#9733;</label>
                                </div>
                                <span className="text-sm text-gray-400 capitalize">{userRating.text}</span>
                            </div>
                            {/*<p className="text-sm mb-4 text-gray-600">Rate <span className="font-medium"></span></p>*/}
                            <textarea name="message" id="message" rows="5"
                                      className="w-full p-4 bg-gray-100 rounded-md"
                                      placeholder="Write your review (optional)" onChange={handleCommentChange}
                                      value={userRating.comment}/>
                            <div className="text-right mt-4 flex justify-end items-center gap-4">
                                {isError &&
                                    <span
                                        className="text-sm text-red-500">Error submitting review, please try again.</span>
                                }
                                <button type="submit" disabled={isLoading}
                                        className="bg-bColor text-white rounded-full py-2 px-6 font-bold hover:bg-bColor-dark disabled:bg-gray-800 w-28 h-10 flex justify-center items-center">
                                    {isLoading
                                        ? <img src="/assets/round-loader.svg" alt="" className="w-4"/>
                                        : "Submit"}
                                </button>
                            </div>
                        </form>
                        :
                        <div className="bg-white rounded-2xl px-8 py-10 flex-grow text-center font-thin">
                            <div className="space-y-4 bg-gray-100 rounded-2xl p-10">
                                <h3 className="text-gray-500 mb-2 text-lg flex justify-center items-center gap-2">
                                    <img src="/assets/info.svg" alt="" className="w-4 h-4"/>
                                    <span>Please login to review</span>
                                </h3>
                                <div>
                                    <a href={`//account.${process.env.REACT_APP_BASE_DOMAIN}/signin?goto=${window.location.href}`}
                                       className="rounded-full bg-bColor text-white py-1.5 px-6">
                                        Login
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                </>
                : ""
            }
            {isSuccess &&
                <div className="flex justify-center items-center mt-6">
                    <div className="bg-green-50 border border-green-200 space-x-2 rounded-lg p-4">
                        <img src="/assets/check-circle.png" alt="" className="w-5 inline-block"/>
                        <span>Review submitted successfully. </span>
                    </div>
                </div>
            }
        </>
    )
};
