import {Link} from "react-router-dom";
import routes from "../../constants/routes";

export default function EducationMainMenuWithBackground({background, height, data}) {
    return (
        <div style={{backgroundImage: `url(${background})`, height: `${height}`}} className="relative bg-fixed bg-no-repeat bg-cover bg-center">
            <div
                className="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b from-black via-transparent to-transparent opacity-90"/>
            <header
                className="max-w-1170 mx-auto flex justify-between items-center pt-4 text-white font-light relative">
                <Link to={`/${data.slug}`} className="flex items-center">
                    <img src={data.logo?.path} alt="" width={60} height={60} className="rounded-full"/>
                    <h1 className="uppercase ml-3 w-40 leading-tight" title={data.name}>
                        {data.name.slice(0,30)} {data.name.length > 30 && "..."}
                    </h1>
                </Link>
                <nav className="space-x-4">
                    <Link to={`/${data.slug}`}>Home</Link>
                    <Link to={`/${data.slug}/events`}>Events</Link>
                    <Link to={`/${data.slug}/career`}>Career</Link>
                    <Link to={`/${data.slug}/gallery`}>Gallery</Link>
                    <Link to={`/${data.slug}/team`}>Team</Link>
                    <Link to={`/${data.slug}/about`}>About</Link>
                </nav>
            </header>
        </div>
    )
}