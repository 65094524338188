import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {createPostRequest, createRequest} from "../network/api";
import {baseUrl, baseUrlVoice} from "../constants/endpoints";


export const directoryApi = createApi({
    reducerPath: "directoryApi",
    baseQuery: fetchBaseQuery({baseUrl}),
    tagTypes: ["Organization"],
    endpoints: (builder) => ({
        getDirectoryList: builder.query({
            query: (filter) => createRequest(`/organization?filter=${filter.type || ""}&places=${filter.places || ""}&q=${filter.q || ""}${filter.extras || ""}&page=${filter.page || ""}`)
        }),
        getOrgBySlug: builder.query({
            query: (slug) => createRequest(`/organization/${slug}`),
            providesTags: ["Organization"]
        }),
        doPostReview: builder.mutation({
            query: (reviewData) => createPostRequest(`${baseUrlVoice}/comment?superman=nt`, reviewData),

            async onQueryStarted(reviewData, {dispatch, queryFulfilled}) {
                try {
                    const {data: postedReview} = await queryFulfilled
                    console.log(dispatch)
                    const patchResult = dispatch(
                        //reviewData.slug is necessary to locate the exact cached data, no where in rtk docs is it mentioned.
                        // lost 2 days trying to figure this out
                        directoryApi.util.updateQueryData('getOrgBySlug', reviewData.slug, (draft) => {
                            //push new review at index 0
                            draft.organization.user_reviews.unshift(postedReview.payload)
                            draft.organization.is_rated = true
                        })
                    )
                } catch {
                    console.log("error")
                }
            },

        }),
        getOrgGalleryImages: builder.query({
            query: (slug) => createRequest(`/organization/${slug}/gallery`)
        }),
        getOrgTeams: builder.query({
            query: (slug) => createRequest(`/organization/${slug}/teams`)
        }),
        getOrgEvents: builder.query({
            query: (slug) => createRequest(`/organization/${slug}/events`)
        }),
        getOrgEventSingle: builder.query({
            query: (data) => createRequest(`/organization/${data.slug}/events/${data.eventSlug}`)
        }),
        getSearchBarResults: builder.mutation({
            query: (keyword) => createPostRequest(`/organization/autocomplete?q=${keyword}`)
        }),
        getAllStateList: builder.query({
            query: () => createRequest(`/address?type=state`)
        }),
        getDistrictList: builder.query({
            query: (state) => createRequest(`address?type=district&parent_q=${state}`)
        }),
        getNagarList: builder.query({
            query: (district) => createRequest(`address?type=nagar&parent_q=${district}`)
        })
    })
})

export const {
    useGetDirectoryListQuery,
    useGetAllStateListQuery,
    useGetDistrictListQuery,
    useGetNagarListQuery,
    useGetSearchBarResultsMutation,
    useGetOrgBySlugQuery,
    useGetOrgGalleryImagesQuery,
    useGetOrgTeamsQuery,
    useGetOrgEventsQuery,
    useGetOrgEventSingleQuery,
    useDoPostReviewMutation,
} = directoryApi;

// export const {useGetProfileQuery} = authApi;