import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {createRequest} from "../network/api";
import {baseUrlVoice as baseUrl} from "../constants/endpoints";

export const voiceApi = createApi({
    reducerPath: "voiceApi",
    baseQuery: fetchBaseQuery({baseUrl}),
    endpoints: (builder) => ({
        getPostList: builder.query({
            query: (filter) => createRequest(`/unauth/entertainment?superman=${filter.type}&next=${filter.next || ""}`)
        }),
    })
})

export const {
    useGetPostListQuery,
} = voiceApi;
