import {Link} from "react-router-dom";
import {useState} from "react";
import DirectionDialog from "./DirectionDialog";
import {siteLinks} from "../constants/siteLinks";

export default function GenericOrgFooter({data}) {

    const {ward_data, nagar_data, district_data} = data.address;

    let [isOpen, setIsOpen] = useState(false)

    const handleDirectionDialog = () => {
        setIsOpen(prevState => !prevState)
    }

    return (
        <>
            <footer className="bg-orgFooterGrey-default text-white pt-16 text-xs">
                <div className="mx-auto max-w-1000">
                    <div className="flex justify-between items-center space-x-4">
                        <nav className="max-w-xs grid gap-4 grid-cols-5 min-w-max">
                            <Link to={`/${data.slug}`}>Home</Link>
                            <Link to={`/${data.slug}/events`}>Events</Link>
                            <Link to={`/${data.slug}/gallery`}>Gallery</Link>
                            <Link to={`/${data.slug}/teams`}>Teams</Link>
                            <Link to={`/${data.slug}/jobs`}>Jobs</Link>
                            <Link to={`/${data.slug}/about`}>About</Link>
                        </nav>
                        <div className="text-center flex items-center justify-center flex-col">
                            <img src={data.logo?.path || "/logo_only.jpg"} alt="" height={50} width={50}
                                 title={data.name}
                                 className="w-20 mb-4"/>
                            <span>{`Add.: ${nagar_data?.name} ${ward_data?.name}, ${district_data?.name}`}</span>
                            {data.phone &&
                                <div>
                                    Tel: {data.phone.map((current, i) =>
                                    <span
                                        key={current.value}>{`${current.value} ${(i !== data.phone.length - 1) ? "," : ""}`}</span>)}
                                </div>
                            }
                            {data.email &&
                                <div>
                                    Email: {data.email.map((current, i) =>
                                    <span
                                        key={current.value}>{`${current.value} ${(i !== data.email.length - 1) ? "," : ""}`}</span>)}
                                </div>
                            }
                        </div>
                        <div className="space-y-6 min-w-max">
                            <div>
                                <span>Connect:</span>
                                <ul className="flex space-x-3 mt-1">
                                    {data.social_media?.filter(current => current.url).map(current => {
                                        return <li key={current.name}><a href={current.url} target="_blank"
                                                                         rel="noreferrer">
                                            <img src={`/assets/${current.type}_gen_org.svg`} alt=""
                                                 className="w-8"/></a>
                                        </li>
                                    })}

                                </ul>
                            </div>
                            <div>
                                <span>Find Us on Google Maps</span>
                                <button
                                    className="bg-gray-100 text-gray-900 rounded-full py-3 px-7 block font-medium mt-1"
                                    onClick={handleDirectionDialog}>
                                    Get Direction
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-700 py-4 mt-16">
                        <div
                            className="flex justify-between flex-col md:flex-row items-center space-y-4 md:space-y-0">
                            <div className="flex space-x-4">
                                <Link to={`/${siteLinks.about}`}>About</Link>
                                <Link to={`/${siteLinks.career}`}>Career</Link>
                                <Link to={`/${siteLinks.gallery}`}>Gallery</Link>
                                <Link to={`/${siteLinks.advertising}`}>Advertising</Link>
                            </div>
                            <span className="mr-4">@Copyrights 2021 <Link to={`/${data.slug}`} className="font-bold">{data.name}</Link>. Powered by <Link
                                to="/" className="font-bold">Find All Nepal</Link></span>
                            <div className="flex space-x-4">
                                <Link to={siteLinks.privacy}>Privacy Policy</Link>
                                <Link to={siteLinks.terms}>Terms</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <DirectionDialog handleDirectionDialog={handleDirectionDialog} isOpen={isOpen} setIsOpen={setIsOpen}/>
        </>
    )
}