import {Outlet} from "react-router-dom";
import VoiceHeaderNew from "../../components/voice/VoiceHeaderNew";
import VoiceSidebar from "../../components/VoiceSidebar";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import CreatePostPopup from "../../components/CreatePostPopup";
import PleaseLoginPopup from "../../components/PleaseLoginPopup";
import {doResetCreateErrors, doResetImageList, doSetImageError, doToggleLoginPopup} from "../../store/voiceSlice";

export const postTypes = ["literature", "jokes", "opinion", "politics", "status", "idea"];

export default function VoiceLayout() {

    const [showCreatePost, setShowCreatePost] = useState(false);
    // const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [selectedType, setSelectedType] = useState("");
    const {data, isLoading} = useSelector(state => state.user);
    const {showLoginPopup} = useSelector(state => state.voice);

    const dispatch = useDispatch();

    const handleShowLoginPopup = (value) => {
        dispatch(doToggleLoginPopup(value))
        // dispatch(doToggleLoginPopup(!showLoginPopup))
        // setShowLoginPopup(prevState => !prevState)
    }

    const handleShowCreatePost = () => {
        setShowCreatePost(prevState => !prevState);
        dispatch(doResetCreateErrors());
        dispatch(doSetImageError({status: false, message: ""}));
        dispatch(doResetImageList());
    }

    return (
        <div className="bg-gray-100 pb-20">
            {/*to make sure tailwind includes these classes*/}
            <div className="col-span-[1] row-span-[1] row-span-[2] col-span-[2]"/>
            <VoiceHeaderNew handleShowCreatePost={handleShowCreatePost} handleShowLoginPopup={handleShowLoginPopup}/>
            <div className="max-w-960 mx-auto mt-4 grid grid-cols-[1fr_300px] gap-6 min-h-screen items-start">
                <div>
                    {(!isLoading && data?.user) &&
                        <div className="bg-white p-4 mb-4 rounded-md">
                            <div className="bg-gray-100 px-6 py-2.5 flex space-x-6 items-center">
                                <DisplayPic/>
                                <span onClick={handleShowCreatePost}
                                      className="text-gray-400 text-sm font-light flex-grow cursor-text">Write your thoughts</span>
                                <img src="/assets/image.png" alt="" className="pointer-events-none"/>
                            </div>
                        </div>
                    }
                    <Outlet/>
                </div>
                <VoiceSidebar showCreatePost={showCreatePost} handleShowCreatePost={handleShowCreatePost}
                              selectedType={selectedType} setSelectedType={setSelectedType}/>
            </div>
            <CreatePostPopup showCreatePost={showCreatePost} handleShowCreatePost={handleShowCreatePost}
                             setShowCreatePost={setShowCreatePost}
                             selectedType={selectedType} setSelectedType={setSelectedType}/>
            <PleaseLoginPopup showLoginPopup={showLoginPopup} handleShowLoginPopup={handleShowLoginPopup}/>
        </div>
    );
}

function DisplayPic() {

    const {data, isLoading} = useSelector(state => state.user);
    const regex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');


    if (!isLoading && data?.user) {
        let initials = [...data?.user?.displayName.matchAll(regex)] || [];
        initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
        return (
            <div className="w-11 h-11 rounded-full flex justify-center items-center">
                {data?.user?.image
                    ? <img src={data?.user?.image?.path} alt="" className="w-11 h-11 rounded-full pointer-events-none"
                           width={44} height={44}/>
                    : <span
                        className="w-11 h-11 rounded-full border-2 border-gray-200 bg-red-500 text-white block text-sm flex items-center justify-center">{initials}</span>
                }
            </div>
        )
    }

    return (
        <div className="w-11 h-11 rounded-full flex justify-center items-center bg-gray-200"/>
    )

}