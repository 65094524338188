import {Helmet} from "react-helmet-async";
import Carousel from "react-multi-carousel";
import React, {useEffect, useState} from "react";
import axios from "axios";
import PageTransitionLoader from "../components/PageTransitionLoader";
import DirectionDialog from "../components/DirectionDialog";
import RatingAndReview from "../components/RatingAndReview";

export default function TouristAreaLayout({data}) {

    // const [orgData, setOrgData] = useState({});

    const [isOpen, setIsOpen] = useState(false)

    const handleDirectionDialog = () => {
        setIsOpen(prevState => !prevState)
    }

    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta property="og:description" content=""/>
                {/*<meta property="og:image" content={`${data.featuredImage?.path || ""}`}/>*/}
            </Helmet>
            <main>
                {/*top purple ragged edges (get direction button container)*/}
                <section id="hero">
                    <div className="relative flex justify-center z-10">
                        <img src="/assets/images/tourist/top.svg" alt=""
                             className="w-full absolute inset-0 pointer-events-none"/>
                        <button onClick={handleDirectionDialog}
                                className="rounded-full border-2 border-white relative z-10 flex items-center p-0.5 pr-2 space-x-2 mt-5 text-white hover:bg-white hover:text-bColor transition">
                            <div className="bg-white rounded-full p-0.5 border border-bColor">
                                <img src="/assets/images/ICON_MAPS.svg" alt="" className="p-1 w-8"/>
                            </div>
                            <span className="font-medium">Get Direction</span>
                        </button>
                    </div>

                    <div className="-mt-5 relative">
                        {/*<img src="https://placekitten.com/1920/800" alt="" className="w-full min-h-screen max-h-[600]"/>*/}
                        <img src={data.featuredImage.path} width={1920} height={800} alt=""
                             className="w-full min-h-screen max-h-[600]"/>
                        <div className="absolute inset-0 flex items-center">
                            <div className="relative">
                                <img src="/assets/images/tourist/text_back.svg" alt="" className="ml-28"/>
                                <h1 className="text-5xl text-black font-bold absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xl uppercase text-center">
                                    {data.name}
                                </h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="introduction">
                    <img src="/assets/images/tourist/white.svg" alt="" className="-mt-32 relative z-10"/>
                    <div className="max-w-1170 mb-24 mx-auto">
                        <div className="mx-4">
                            <h2 className="uppercase mb-8 text-4xl">introduction</h2>
                            <div dangerouslySetInnerHTML={{__html: data.description}}/>
                        </div>
                    </div>
                </section>

                <section id="messages">
                    <div className="relative flex items-center mb-24">
                        <img src="/assets/images/tourist/message.svg" alt="" className=""/>
                        <div
                            className="absolute inset-0 max-w-1170 mx-auto text-white flex justify-center flex-col">
                            <Carousel
                                responsive={{desktop: {breakpoint: {max: 3000, min: 0}, items: 1}}}
                                showDots={false} renderButtonGroupOutside slidesToSlide={1} infinite arrows={false}
                                autoPlay pauseOnHover customButtonGroup={<MessageSliderNavs/>}>
                                {(data?.feature_message.length > 0)
                                    ? data.feature_message.map(current => {
                                        return (
                                            <article className="flex items-center space-x-8" key={current.slug}>
                                                <div className="relative flex items-center justify-center h-96">
                                                    <img src="/assets/images/tourist/text_back.svg" alt=""
                                                         className="max-w-md pointer-events-none"/>
                                                    <img src={current.image.path} alt=""
                                                         className="rounded-full absolute w-56 h-56 pointer-events-none"/>
                                                </div>
                                                <div className="flex flex-col max-w-3xl">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="59.999"
                                                         height="39.943" viewBox="0 0 59.999 39.943"
                                                         className="mb-4">
                                                        <g id="Group_168" data-name="Group 168"
                                                           transform="translate(-779.582 -1811.998)">
                                                            <path id="Op_component_1" data-name="Op component 1"
                                                                  d="M693.749,1090.833H682.4l-8.572,15.977h0v22.555a1.463,1.463,0,0,0,1.513,1.41h22.689a1.463,1.463,0,0,0,1.513-1.41v-21.146a1.462,1.462,0,0,0-1.513-1.409H686.69l7.894-14.717C694.959,1091.4,694.585,1090.833,693.749,1090.833Z"
                                                                  transform="translate(140.034 721.165)"
                                                                  fill="#fff"/>
                                                            <path id="Op_component_2" data-name="Op component 2"
                                                                  d="M671.082,1090.833H659.738l-8.571,15.977h0v22.555a1.463,1.463,0,0,0,1.512,1.41h22.689a1.463,1.463,0,0,0,1.513-1.41v-21.146a1.462,1.462,0,0,0-1.513-1.409H664.023l7.895-14.717C672.292,1091.4,671.918,1090.833,671.082,1090.833Z"
                                                                  transform="translate(128.416 721.165)"
                                                                  fill="#fff"/>
                                                        </g>
                                                    </svg>
                                                    <h3 className="text-3xl uppercase mb-5">
                                                        {current.message}
                                                        <span className="ml-2 font-bold">{current.from}</span>
                                                    </h3>
                                                    <div className="text-lg font-thin tracking-wide"
                                                         dangerouslySetInnerHTML={{__html: current.desc}}/>
                                                </div>
                                            </article>
                                        )
                                    })
                                    : <div/>
                                }
                            </Carousel>
                        </div>
                    </div>
                </section>

                <section id="gallery">
                    <div className="max-w-1170 mb-24 mx-auto">
                        <div className="relative mb-12 pl-4">
                            <h2 className="text-9xl text-gray-200 leading-none">Gallery</h2>
                            <span
                                className="text-4xl font-light tracking-widest absolute bottom-0 leading-relaxed">IMAGE</span>
                        </div>
                        <div className="relative">
                            <Carousel
                                responsive={{desktop: {breakpoint: {max: 3000, min: 0}, items: 3}}}
                                showDots={false} renderButtonGroupOutside slidesToSlide={1} infinite arrows={false}
                                autoPlay pauseOnHover customButtonGroup={<GallerySliderNavs/>}>
                                {data.featuredGallery?.map((current, i) => {
                                    return <img src={current.path} key={i} alt="" width={400} height={400}
                                                className="cursor-pointer h-[400px] w-[400px] px-4 pointer-events-none"
                                                loading={"lazy"}/>
                                })}
                            </Carousel>
                            {data.featuredGallery?.length === 0 && <div className="ml-6">No image in gallery</div>}
                        </div>
                    </div>
                </section>

                <section id="review">
                    <div className="bg-gray-100">
                        <div className="max-w-1170 mb-24 mx-auto py-16 px-8">
                            <h2 className="uppercase mb-8 text-4xl">Ratings & Reviews</h2>
                            <RatingAndReview data={data}/>
                        </div>
                    </div>
                </section>

            </main>

            <footer>
                <img src="/assets/images/tourist/footer.svg" alt="" className="w-full"/>

            </footer>
            <DirectionDialog handleDirectionDialog={handleDirectionDialog} isOpen={isOpen} setIsOpen={setIsOpen}/>
        </>
    )

    // return (
    //     <div className="flex justify-center items-center flex-col h-72 bg-bColor-50">
    //         <PageTransitionLoader size={28}/>
    //         <span className="text-sm">LOADING</span>
    //     </div>
    //
    // )
}

const MessageSliderNavs = ({next, previous, goToSlide, ...rest}) => {
    // const {carouselState: {currentSlide}} = rest;
    return (
        <div className="absolute top-24 right-5 space-x-3 z-20">
            <button
                className="p-2.5 border-2 border-white text-white rounded-full hover:text-bColor hover:bg-white"
                onClick={() => previous()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"/>
                </svg>
            </button>
            <button
                className="p-2.5 border-2 border-white text-white rounded-full hover:text-bColor hover:bg-white"
                onClick={() => next()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                </svg>
            </button>
        </div>
    );
};

const GallerySliderNavs = ({next, previous, goToSlide, ...rest}) => {
    // const {carouselState: {currentSlide}} = rest;
    return (
        <div className="absolute -top-24 right-5 space-x-3 z-20">
            <button
                className="p-2.5 border-2 border-bColor text-bColor rounded-full hover:text-white hover:bg-bColor"
                onClick={() => previous()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"/>
                </svg>
            </button>
            <button
                className="p-2.5 border-2 border-bColor text-bColor rounded-full hover:text-white hover:bg-bColor"
                onClick={() => next()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                </svg>
            </button>
        </div>
    );
};
