import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import EducationFooter from "../../components/education/EducationFooter";
import EducationSectionHeading from "../../components/education/EducationSectionHeading";
import {useParams} from "react-router-dom";
import {useGetOrgTeamsQuery} from "../../services/directoryApi";
import {Helmet} from "react-helmet-async";

export default function Team({data}) {


    const param = useParams();

    const {isFetching, data: teamData} = useGetOrgTeamsQuery(param.slug);

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`Teams | ${data.name}`}/>
                <meta property="og:url" content={`https://findallnepal.com/${data.slug}/teams`}/>
                <link rel="canonical" href={`https://findallnepal.com/${data.slug}/teams`}/>
                <title>{`Teams | ${data.name}`}</title>
            </Helmet>

            <section id="hero">
                <EducationTopMenu data={data}/>
                <EducationMainMenuWithBackground
                    background={data.featuredImage.path || "/assets/images/edu_events_bg.png"} height="25rem"
                    data={data}/>
            </section>
            <main className="my-10">
                <div className="max-w-1170 mx-auto">
                    <EducationSectionHeading text="Meet Our"/>
                    <span className="block text-5xl font-bold text-black mb-12 uppercase">Team Members</span>
                    {isFetching && <div className="mx-auto my-10">loading...</div>}
                    {teamData?.organizationWithTeam.teams.map(current => {
                    // {teams.map(current => {
                        return (
                            <section id={current.title} className="mb-16" key={current.order}>
                                <div className="relative">
                                    <h3 className="font-bold text-lg relative bg-white z-10 inline-block pr-2">
                                        {current.title}
                                    </h3>
                                    <div className="border border-gray-300 absolute top-1/2 w-full"/>
                                </div>
                                <div className="grid grid-cols-3 gap-3 mt-10">
                                    {current.team_member.map(item => {
                                        return (
                                            <div className="relative rounded-sm edu_team_member transition h-full"
                                                 key={item.order}>
                                                <img src={item.photo.path || "/assets/images/user-placeholder.png"} alt=""
                                                     className="w-full h-full bg-gray-500 pointer-events-none"/>
                                                <div
                                                    className="absolute bottom-4 left-10 right-10 text-center py-2 rounded-sm z-10">
                                                    <div className="p-3 bg-white rounded-sm">
                                                        <strong
                                                            className="block leading-none text-xl">{item.name}</strong>
                                                        <span className="text-sm text-gray-400">{item.post}</span>
                                                    </div>
                                                </div>
                                                {/*<div*/}
                                                {/*    className="bg-education absolute top-0 bottom-0 left-0 right-0 rounded-sm z-20 hidden edu_team_member_hover_content">*/}
                                                {/*    <div*/}
                                                {/*        className="text-white flex justify-evenly items-center flex-col h-full px-10 py-6">*/}
                                                {/*        <div className="flex-grow mt-10">*/}
                                                {/*            <div className="flex justify-center space-x-4 mb-6">*/}
                                                {/*                <img src="/assets/images/about_email.png" alt=""/>*/}
                                                {/*                <img src="/assets/images/about_phone.png" alt=""/>*/}
                                                {/*            </div>*/}
                                                {/*            <div className="flex items-center ">*/}
                                                {/*                <a href="/"><img src="/assets/images/instagram.svg" alt=""*/}
                                                {/*                                 width={30}*/}
                                                {/*                                 className="inline-block mr-2"/></a>*/}
                                                {/*                <a href="/"><img src="/assets/images/facebook.svg" alt=""*/}
                                                {/*                                 width={30}*/}
                                                {/*                                 className="inline-block mr-2"/></a>*/}
                                                {/*                <a href="/"><img src="/assets/images/linkedin.svg" alt=""*/}
                                                {/*                                 width={30}*/}
                                                {/*                                 className="inline-block mr-2"/></a>*/}
                                                {/*                <a href="/"><img src="/assets/images/twitter.svg" alt=""*/}
                                                {/*                                 width={30}*/}
                                                {/*                                 className="inline-block mr-2"/></a>*/}
                                                {/*                <a href="/"><img src="/assets/images/youtube.svg" alt=""*/}
                                                {/*                                 width={36}*/}
                                                {/*                                 className="inline-block mr-2"/></a>*/}
                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*        <div*/}
                                                {/*            className="p-3 bg-white rounded-sm text-center w-full justify-self-end">*/}
                                                {/*            <strong className="block leading-none text-xl text-black">Samayak*/}
                                                {/*                Sharma</strong>*/}
                                                {/*            <span className="text-sm text-gray-400">Chairperson</span>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                        )
                    })}

                </div>
            </main>
            <EducationFooter data={data}/>
        </>
    )
}

// const teams = [
//     {
//         "title": "Accountant",
//         "subtitle": "Accountant",
//         "order": 0,
//         "status": 1,
//         "team_member": [
//             {
//                 "name": "Bhuma Oli",
//                 "post": "Accountant",
//                 "photo": {
//                     "name": "Bhuma Oli.JPG",
//                     "alt": "Bhuma Oli.JPG",
//                     "path": "https://cdn.findallnepal.com/uploads/images/2021/04/15/ChEV7weyz1IfBtnl8mXcouMdp@400x400.jpg",
//                     "size": "8950244",
//                     "desc": "Name: Bhuma Oli.JPG\n Size:8.54 MB\nDate: Thu, 15 Apr 2021 16:02:41 +0545",
//                     "created_at": "2021-04-15T12:17:41.000000Z"
//                 },
//                 "order": 0,
//                 "status": 1,
//                 "social_links": [
//                     {
//                         "value": "https://www.facebook.com/neelamwoli",
//                         "type": "facebook"
//                     }
//                 ],
//                 "quote": null
//             }
//         ]
//     },
//     {
//         "title": "Receptionist",
//         "subtitle": "Receptionist",
//         "order": 1,
//         "status": 1,
//         "team_member": [
//             {
//                 "name": "Reshu Chaudhary",
//                 "post": "Receptionist",
//                 "photo": {
//                     "name": "Reshu Chaudhary.JPG",
//                     "alt": "Reshu Chaudhary.JPG",
//                     "path": "https://cdn.findallnepal.com/uploads/images/2021/04/15/QF98GDUTHwgc3hpnV5lvsKBoS@400x400.jpg",
//                     "size": "9015535",
//                     "desc": "Name: Reshu Chaudhary.JPG\n Size:8.60 MB\nDate: Thu, 15 Apr 2021 16:06:11 +0545",
//                     "created_at": "2021-04-15T12:21:12.000000Z"
//                 },
//                 "order": 0,
//                 "status": 1,
//                 "social_links": [],
//                 "quote": null
//             }
//         ]
//     },
//     {
//         "title": "Data Entry",
//         "subtitle": "Data Entry",
//         "order": 2,
//         "status": 1,
//         "team_member": [
//             {
//                 "name": "Rama Budha",
//                 "post": "Data Entry",
//                 "photo": {
//                     "name": "Rama Budha.JPG",
//                     "alt": "Rama Budha.JPG",
//                     "path": "https://cdn.findallnepal.com/uploads/images/2021/04/16/Sa83rpXUtWT9KjO1Hkn2YhVw6@400x400.jpg",
//                     "size": "7732287",
//                     "desc": "Name: Rama Budha.JPG\n Size:7.37 MB\nDate: Fri, 16 Apr 2021 09:41:17 +0545",
//                     "created_at": "2021-04-16T05:56:18.000000Z"
//                 },
//                 "order": 0,
//                 "status": 1,
//                 "social_links": [],
//                 "quote": null
//             },
//             {
//                 "name": "Eliza Chaudhary",
//                 "post": "Data Entry",
//                 "photo": {
//                     "name": "Eliza Chaudhary.JPG",
//                     "alt": "Eliza Chaudhary.JPG",
//                     "path": "https://cdn.findallnepal.com/uploads/images/2021/04/16/Ew6NaUgnVhiyOT0qHrGekzlW_@400x400.jpg",
//                     "size": "7754891",
//                     "desc": "Name: Eliza Chaudhary.JPG\n Size:7.40 MB\nDate: Fri, 16 Apr 2021 09:44:03 +0545",
//                     "created_at": "2021-04-16T05:59:04.000000Z"
//                 },
//                 "order": 1,
//                 "status": 1,
//                 "social_links": [],
//                 "quote": null
//             },
//             {
//                 "name": "Rejina Giri",
//                 "post": "Data Entry",
//                 "photo": {
//                     "name": "Rejina Giri.JPG",
//                     "alt": "Rejina Giri.JPG",
//                     "path": "https://cdn.findallnepal.com/uploads/images/2021/04/16/DRqLXvZ8bGw_P6WjmicknCtBS@400x400.jpg",
//                     "size": "8273925",
//                     "desc": "Name: Rejina Giri.JPG\n Size:7.89 MB\nDate: Fri, 16 Apr 2021 09:45:29 +0545",
//                     "created_at": "2021-04-16T06:00:30.000000Z"
//                 },
//                 "order": 2,
//                 "status": 1,
//                 "social_links": [
//                     {
//                         "value": "https://touch.facebook.com/rejina.giri.752",
//                         "type": "facebook"
//                     }
//                 ],
//                 "quote": null
//             },
//             {
//                 "name": "Laxmi Dangi",
//                 "post": "Data Entry",
//                 "photo": {
//                     "name": "Laxmi Dangi.JPG",
//                     "alt": "Laxmi Dangi.JPG",
//                     "path": "https://cdn.findallnepal.com/uploads/images/2021/04/16/_w5CzNq1nRPk6gMHEtpOXTiYb@400x400.jpg",
//                     "size": "7479054",
//                     "desc": "Name: Laxmi Dangi.JPG\n Size:7.13 MB\nDate: Fri, 16 Apr 2021 09:46:54 +0545",
//                     "created_at": "2021-04-16T06:01:54.000000Z"
//                 },
//                 "order": 3,
//                 "status": 1,
//                 "social_links": [
//                     {
//                         "value": "https://touch.facebook.com/profile.php?id=100010455782430&ref=content_filter",
//                         "type": "facebook"
//                     }
//                 ],
//                 "quote": null
//             },
//             {
//                 "name": "Huma Rana",
//                 "post": "Data Entry",
//                 "photo": {
//                     "name": "Huma Rana.JPG",
//                     "alt": "Huma Rana.JPG",
//                     "path": "https://cdn.findallnepal.com/uploads/images/2021/04/16/0wXaRoNi1vf_tMh36JeYLbOnp@400x400.jpg",
//                     "size": "7230042",
//                     "desc": "Name: Huma Rana.JPG\n Size:6.90 MB\nDate: Fri, 16 Apr 2021 09:48:46 +0545",
//                     "created_at": "2021-04-16T06:03:47.000000Z"
//                 },
//                 "order": 4,
//                 "status": 1,
//                 "social_links": [
//                     {
//                         "value": "https://touch.facebook.com/huma.magar.184?tsid=0.33791890276540326&source=result",
//                         "type": "facebook"
//                     }
//                 ],
//                 "quote": null
//             }
//         ]
//     },
//     {
//         "title": "Marketing",
//         "subtitle": "Marketing",
//         "order": 3,
//         "status": 1,
//         "team_member": [
//             {
//                 "name": "Bimala Pokhrel",
//                 "post": "Marketing",
//                 "photo": {
//                     "name": "Bimala pokhrel.JPG",
//                     "alt": "Bimala pokhrel.JPG",
//                     "path": "https://cdn.findallnepal.com/uploads/images/2021/04/16/j5vhbMYVaetGlcQKPySuH_6fp@400x400.jpg",
//                     "size": "5364760",
//                     "desc": "Name: Bimala pokhrel.JPG\n Size:5.12 MB\nDate: Fri, 16 Apr 2021 09:53:35 +0545",
//                     "created_at": "2021-04-16T06:08:36.000000Z"
//                 },
//                 "order": 0,
//                 "status": 1,
//                 "social_links": [],
//                 "quote": null
//             },
//             {
//                 "name": "Salina Chaudhary",
//                 "post": "Marketing",
//                 "photo": {
//                     "name": "salina chaudhary.JPG",
//                     "alt": "salina chaudhary.JPG",
//                     "path": "https://cdn.findallnepal.com/uploads/images/2021/04/16/p1JgIzotaYG8T4K6XZfDClrdx@400x400.jpg",
//                     "size": "5237003",
//                     "desc": "Name: salina chaudhary.JPG\n Size:4.99 MB\nDate: Fri, 16 Apr 2021 10:44:58 +0545",
//                     "created_at": "2021-04-16T06:59:59.000000Z"
//                 },
//                 "order": 1,
//                 "status": 1,
//                 "social_links": [],
//                 "quote": null
//             },
//             {
//                 "name": "Prabin Chaudhary",
//                 "post": "Marketing",
//                 "photo": {
//                     "name": "Prabin Chaudhary .jpg",
//                     "alt": "Prabin Chaudhary .jpg",
//                     "path": "https://cdn.findallnepal.com/uploads/images/2021/04/16/dO6vSgGKAyts1fPZqkiEIHQLV@400x400.jpg",
//                     "size": "476561",
//                     "desc": "Name: Prabin Chaudhary .jpg\n Size:465.39 KB\nDate: Fri, 16 Apr 2021 10:47:31 +0545",
//                     "created_at": "2021-04-16T07:02:31.000000Z"
//                 },
//                 "order": 2,
//                 "status": 1,
//                 "social_links": [],
//                 "quote": null
//             }
//         ]
//     }
// ]