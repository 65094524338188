import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import {Link, useParams} from "react-router-dom";
import EducationFooter from "../../components/education/EducationFooter";
import routes from "../../constants/routes";
import {useGetOrgEventSingleQuery} from "../../services/directoryApi";
import DOMPurify from "dompurify";
import {DateTime} from "luxon";
import {Helmet} from "react-helmet-async";

export default function EventDetailsPage({data}) {

    const param = useParams();

    const {data: eventData, isFetching} = useGetOrgEventSingleQuery({slug: param.slug, eventSlug: param.eventSlug});

    if (isFetching) {
        return (
            <EventDetailsPageLayout data={data}>
                <div className="mx-auto my-16">loading..</div>
            </EventDetailsPageLayout>
        )
    }

    if (!isFetching && eventData.organizationWithSingleEvent) {

        const {event} = eventData.organizationWithSingleEvent;

        const eventDetail = event[0]

        return (
            <EventDetailsPageLayout data={data}>
                <Helmet>
                    <meta property="og:title" content={`Events | ${eventDetail.name}`}/>
                    <meta property="og:url" content={`https://findallnepal.com/${eventDetail.slug}/events`}/>
                    <link rel="canonical" href={`https://findallnepal.com/${eventDetail.slug}/events`}/>
                    <title>{`Events | ${eventDetail.name}`}</title>
                </Helmet>
                <div className="flex justify-between mb-6">
                    <h2 className="font-bold text-4xl text-black capitalize">{eventDetail.name}</h2>
                    <button className="rounded-lg text-education py-2 px-3 border border-education bg-white">
                        <img src="/assets/images/edu_share.svg" alt="" className="w-4 h-4"/>
                    </button>
                </div>
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <img src={`${eventDetail.featuredImage.path || "/assets/images/event_thumb2.png"}`}
                             alt="thumbnail for job" className="w-full"/>
                        <div className="mt-4"
                             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(eventDetail.desc, {USE_PROFILES: {html: true}})}}
                        />
                    </div>
                    <div className="w-80 space-y-4">
                        {/*<div className="bg-education p-8 text-white space-y-12">*/}
                        {/*    <ul className="flex justify-between">*/}
                        {/*        <li>*/}
                        {/*            <strong className="text-lg">14 :</strong>*/}
                        {/*            <span className="text-xs block">Days</span>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <strong className="text-lg">14 :</strong>*/}
                        {/*            <span className="text-xs block">Hours</span>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <strong className="text-lg">14 :</strong>*/}
                        {/*            <span className="text-xs block">Minutes</span>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <strong className="text-lg">14 :</strong>*/}
                        {/*            <span className="text-xs block">Seconds</span>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*    <span*/}
                        {/*        className="font-light bg-white rounded-md block p-2 text-education uppercase text-center text-sm">*/}
                        {/*    book your seat*/}
                        {/*</span>*/}
                        {/*</div>*/}
                        <div className="border-2 border-gray-300 p-8">
                            <ul className="space-y-6">
                                <li className="flex items-start space-x-4">
                                    <div className="mt-1">
                                        <img src="/assets/images/user_org.svg" alt="" className="w-5 h-5"/>
                                    </div>
                                    <div>
                                        <strong className="block font-bold text-xl leading-none">Venue</strong>
                                        <span
                                            className="text-sm">Himalayan College Department</span>
                                    </div>
                                </li>
                                <li className="flex items-start space-x-4">
                                    <div className="mt-1">
                                        <img src="/assets/images/time.svg" alt="" className="w-4 h-4"/>
                                    </div>
                                    <div>
                                        <strong
                                            className="block font-bold text-xl leading-none">Start Time</strong>
                                        <span className="text-sm">10:00 AM - 5:00 PM</span>
                                    </div>
                                </li>
                                <li className="flex items-start space-x-4">
                                    <div className="mt-1">
                                        <img src="/assets/images/calender.svg" alt="" className="w-4 h-4"/>
                                    </div>
                                    <div>
                                        <strong
                                            className="block font-bold text-xl leading-none">Date</strong>
                                        <span className="text-sm">
                                                    {DateTime.fromISO(eventDetail.from).toLocaleString({locale: 'en-gb'})} - {DateTime.fromISO(eventDetail.to).toLocaleString({locale: 'en-gb'})}
                                                </span>
                                    </div>
                                </li>
                                <li className="flex items-start space-x-4">
                                    <div className="mt-1">
                                        <img src="/assets/images/edu_location.svg" alt="" className="w-4 h-4"/>
                                    </div>
                                    <div>
                                        <strong
                                            className="block font-bold text-xl leading-none">Address</strong>
                                        <span className="text-sm capitalize">{eventDetail.location}</span>
                                    </div>
                                </li>
                            </ul>
                            <Link to="#"
                                  className="block bg-education p-2 text-center text-white rounded-md mt-8 font-bold">
                                <span>Get Direction</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </EventDetailsPageLayout>
        )
    }

    return (
        <EventDetailsPageLayout data={data}>
            <div>
                Something went wrong.
            </div>
        </EventDetailsPageLayout>

    )

}

function EventDetailsPageLayout({data, children}) {
    return (
        <>
            <section id="hero">
                <EducationTopMenu data={data}/>
                <EducationMainMenuWithBackground
                    background={data.featuredImage.path || "/assets/images/edu_events_bg.png"} height="25rem"
                    data={data}/>
            </section>
            <main className="my-10">
                <div className="max-w-1170 mx-auto bg-gray-100 py-10 px-8">
                    {children}
                </div>
            </main>
            {/*<div className="max-w-1170 mx-auto mb-10">*/}
            {/*    <h2 className="text-3xl font-bold mb-6 text-black">Recent Events</h2>*/}
            {/*    <div className="grid grid-cols-2 gap-2">*/}
            {/*        <Link to={`${routes.organization}/white-house-college/events/some-event`}>*/}
            {/*            <article className="bg-gray-100 p-4 flex">*/}
            {/*                <div style={{backgroundImage: "url(/assets/images/event_thumb1.png)"}}*/}
            {/*                     className="w-96 mr-6 bg-center"/>*/}
            {/*                <div className="space-y-3 text-sm">*/}
            {/*                    <h3 className="font-bold text-xl">School Cleaning</h3>*/}
            {/*                    <p className="font-medium">*/}
            {/*                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
            {/*                    </p>*/}
            {/*                    <ul className="max-w-sm">*/}
            {/*                        <li className="flex items-baseline">*/}
            {/*                            <img className="inline-block w-3" src="/assets/images/calender.svg"*/}
            {/*                                 alt=""/>*/}
            {/*                            <span className="ml-2">15 Jul, 2021-10 Aug, 2021</span>*/}
            {/*                        </li>*/}
            {/*                        <li className="flex items-baseline">*/}
            {/*                            <img className="inline-block w-3" src="/assets/images/user_org.svg"*/}
            {/*                                 alt=""/>*/}
            {/*                            <span className="ml-2">Himalayan College Department</span>*/}
            {/*                        </li>*/}
            {/*                        <li className="flex items-baseline">*/}
            {/*                            <img className="inline-block w-3" src="/assets/images/time.svg"*/}
            {/*                                 alt=""/>*/}
            {/*                            <span className="ml-2">10:00 AM - 5:00 PM</span>*/}
            {/*                        </li>*/}
            {/*                        <li className="flex items-baseline">*/}
            {/*                            <img className="inline-block w-2.5" src="/assets/images/location_pin.svg"*/}
            {/*                                 alt=""/>*/}
            {/*                            <span className="ml-2">Chyasal, Lalitpur</span>*/}
            {/*                        </li>*/}
            {/*                    </ul>*/}
            {/*                    <div className="flex space-x-2 items-center text-center">*/}
            {/*                        <span*/}
            {/*                            className="block py-2 bg-education rounded-md text-white flex-grow">Ongoing</span>*/}
            {/*                        <span className="block py-2  bg-gray-400 rounded-md text-white flex-grow">*/}
            {/*                            <img src="/assets/images/share_white.svg" alt=""*/}
            {/*                                 className="inline w-4 mr-2"/>*/}
            {/*                            Share*/}
            {/*                        </span>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </article>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <EducationFooter data={data}/>
        </>
    )
}
