import {Route, Routes} from "react-router-dom";
import Events from "./Events";
import SingleEducationHome from "./SingleEducationHome";
import Career from "./Career";
import Team from "./Team";
import About from "./About";
import Gallery from "./Gallery";
import EventDetailsPage from "./EventDetailsPage";
import CareerDetailsPage from "./CareerDetailsPage";
import FourOFour from "../../components/FourOFour";

export default function EducationRoot({data}) {
    return (
        <Routes>
            <Route path={`gallery`} element={<Gallery data={data}/>}/>
            <Route path={`about`} element={<About data={data}/>}/>
            <Route path={`team`} element={<Team data={data}/>}/>
            <Route path={`career/:careerTitle`} element={<CareerDetailsPage data={data}/>}/>
            <Route path={`career`} element={<Career data={data}/>}/>
            <Route path={`events/:eventSlug`} element={<EventDetailsPage data={data}/>}/>
            <Route path={`events`} element={<Events data={data}/>}/>
            <Route path={`/`} element={<SingleEducationHome data={data}/>}/>
            <Route path={`/*`} element={<FourOFour/>}/>
        </Routes>
    )
}